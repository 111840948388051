@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.philosophy-container {
display: flex;
flex-direction: column;
text-align: center;
justify-content: center;
align-items: center;
font-family: "Lato", sans-serif;
margin-top: 2rem;
/* padding: 1.25rem;
padding-left: 4rem;
padding-right: 4rem; */
}

.philosophy-container h1 {
color:#82480C;
font-family: 'playfair display',sans-serif;
font-weight: 300;
font-size: 3.2rem;
max-width: 100%;
margin-bottom: 1.25rem;
}
.philosophy-container-p{


font-size: 1.2rem;
max-width: 50%;
margin-bottom: 1.25rem;
}
.philosophy-container-navigation{
    width: 100%;
    justify-content: start;
    display: flex;
    align-items: center;
}
.philosophy-container-navigation-body a{
    text-decoration: none;
    color: #82480C;

}
.philosophy-container-navigation-body {
    color: #82480C;
    padding-left: 3rem;
    font-size: 0.9rem;
    font-family: "Lato", sans-serif;

}
.content-section{
    display: flex;
    justify-content: center;
    align-items:center ;
}
.content-section p{
font-size: 1.2rem;
max-width: 50%;
margin-bottom: 4rem;
text-align: justify;
text-align: center;
}

.content-section h2 {
    margin-bottom: 4rem;
    color:#82480C;
font-size: 2.2rem;
font-family: 'Playfair Display' sans-serif;
margin-bottom: 0.625rem;
}

.content-with-image {
display: flex;
flex-direction: column;
align-items: center;
}
.content-with-image h2{
    font-family: "Playfair Display",sans-serif;
}

.content-with-image p {
max-width: 50%;
color:rgb(19, 18, 29);
font-size: 1.2rem;
text-align: justify;
margin-top: 1rem;
margin-bottom: 3rem;
}
.highlight{

    border-bottom:1px solid #82480C;
}

.content-with-image img {
    border-bottom: #82480C 3px solid;
    border-top: #82480C 3px solid;
width: 100%;
height: 100%;
margin-top: 1.25rem;
margin-bottom: 3rem;
}

.vision-section {
border-top:2px solid #82480C;

padding-top: 3rem;
margin-bottom:1rem;
}

.vision-section h2 {
font-size: 2rem;
margin-bottom: 0.625rem;
}

/* .vision-section p {
font-size: 1.125rem;
margin-bottom: 0.625rem;
} */
@media only screen and (max-width:512px){
    .philosophy-container h1 {
        font-size: 2.5rem;
        font-family: 'playfair display',sans-serif;
        margin-bottom: 2rem;
        }
        .philosophy-container-p{
            font-size: 1rem;
            max-width: 80%;
            margin-bottom: 1.25rem;
            }
        .content-section p{
        font-size: 1rem;
        }
        .content-section h2 {
        font-size: 2rem;
        font-family: 'playfair display' sans-serif;
        }
        .content-with-image p {
            font-size: 1rem;
            max-width: 80%;
            }
            .vision-section h2 {
                font-size: 1.1rem;
                margin-bottom: 0.625rem;
                }               
}