@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
.moving-BC2 {
    width: 100%;
    background-color: transparent;
    height: 100%;

}
.Title-MB2{
    /* border: 2px dashed #82480C; */
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 3rem;
    border-radius: 1rem;
    align-items: center;
    padding: 1rem 1rem;
    padding-bottom: 1rem;
    animation: rotateDashedBorder 5s linear infinite;
}

.Title-MB2 h1{
font-family: 'Playfair Display',sans-serif;
color: #82480C;
font-size: 2.8rem;
text-align: center;
}
.Title-MB2 p{
    color: #82480C;
font-size: 1.2rem;
font-family: "Lato", sans-serif;
text-align: center;
width: 80%;
}
.form-MB{
    color: #82480C;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.keep-posted{
    font-family: "Lato", sans-serif;
    font-size: 1rem;
    padding: 0.1rem 2rem;
    border-radius: 5px;
    border: #82480C 2px solid;
    cursor: text;
}
.form-MB button{
    background-color: #82480C;
    color: #F4EFEC;
    font-size: 1rem;
    border: #82480C 1px solid;
    border-radius: 5px;
    padding: 4px 2rem;
    transition: all ease-in-out 0.3s;
    cursor:pointer;
}
.form-MB button:hover{
    border: #82480C 2px solid;
    background-color: #82480C;
    color: #F4EFEC;

}

.moving-imgs2 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: inherit;
    animation: moveTopToBottom 5s ease-in-out alternate infinite;
}

.MovingMain2 {
    display: flex;
    overflow-y: hidden;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    border-top: 2px solid #82480C ;
    border-bottom: 2px solid #82480C ;
    height: 27rem;
    margin-top: 5rem;
    margin-bottom: 3rem;
}

.img-moving2 {
    width: 39%;
    margin-bottom: 2rem;
    border-radius: 1rem;
}

@keyframes moveTopToBottom {
    0% {
        transform: translateY(-100%);
    }
    /* 50% {
        transform: translateY(0%);
    } */
    100% {
        transform: translateY(100%);
    }
}
@media only screen and (max-width:1250px){
    .img-moving2 {
        width: 48%;
        margin-bottom: 2rem;
        border-radius: 1rem;
    }
    
}
@media only screen and (max-width:1100px){
    .img-moving2 {
        width: 60%;
        margin-bottom: 2rem;
        border-radius: 1rem;
    }
    
}
@media only screen and (max-width:910px){
    .Title-MB2 h1{
        text-align: center;
    }
    .Title-MB2 p{
        text-align: center;
    }
    .keep-posted{
        padding: 0.1rem 1rem;
    }
    .form-MB button{
        padding: 4px 0.5rem;
        font-size: 1rem;
    }
    .img-moving2{
        width: 70%;
    }
}



@media only screen and (max-width:768px){
    .Title-MB2 h1{
        font-size: 2rem;
    }
.Title-MB2 p{
    font-size: 0.9rem;
    width: 80%;
}
.img-moving2{
    width: 70%;
}
.keep-posted{
    padding: 0.1rem 1rem;
}
.form-MB button{
    padding: 4px 0.5rem;
    font-size: 1rem;
    
}

}
@media only screen and (max-width:618px){
    .img-moving2{
        width: 80%;
    }
    .moving-BC2{
        height: 75%;
    }
    .Title-MB2{
        gap: 2rem;
    }
    .Title-MB2 h1{
        text-align: center;
        width: 100%;
    }
    .Title-MB2 p{
        width: 100%;
        text-align: center;
    }
    .form-MB{
        flex-direction: column;
    }
    .keep-posted{
        font-size: 0.8rem;
    }
    .form-MB button{
        width: 100%;
        font-size: 0.8rem;
    }
    }

@media only screen and (max-width:450px){

    .Title-MB2{
        width: 90%;
    }
    .Title-MB2 p{
        width: 80%;
    }
    .keep-posted{
        font-size: 0.8rem;
        padding-right: 5rem;
    }
    .form-MB button{
        width: 100%;
        font-size: 0.8rem;
    }

    .MovingMain2{
        flex-direction: column;
        height: 100%;
        overflow-x: hidden;
    }
    .moving-imgs2 {
        margin-top: 1rem;
        margin-bottom: 0rem;
        flex-direction: row;
        width: 70%;
        animation: moveTopToBottom 9s ease-in-out alternate infinite;


    }
    .img-moving2{
        margin-right: 2rem;
        width: 50%;
    }
    @keyframes moveTopToBottom {
        0% {
            transform: translateX(-100%);
        }
        
        100% {
            transform: translateX(100%);
        }
    }
    
}
@media only screen and (max-width:300px){
    .Title-MB2{
        width: 90%;
    }
    .Title-MB2 h1{
        
        font-size: 1.8rem;
    }
    .Title-MB2 p{
        width: 100%;
    }
    .keep-posted{
        padding-right: 3rem;
    }
    
    .moving-imgs2 {
        width: 90%;

    }
    .img-moving2{
        width: 60%;
    }
}