
  .dropdown {
    position: absolute;
    right: 0;
    left: 0;
    margin-left: 2px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.247),
                0 4px 6px -2px rgba(0, 0, 0, 0.267);
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 10rem;
    padding: 0.5rem 0;
    list-style: none;
    background-color: #f8f3e3;
    border-radius: 0.5rem;
    display: none;
  }
  
  .dropdown.show {
    display: block;
  }
  
  .dropdown.dropdown-submenu {
    position: absolute;
    left: 90%;
    top: -0.1rem;
  }
  
  @media only screen and (max-width: 768px){
    .dropdown {
      position: absolute;
      right: 0;
      left: 0;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.247),
                  0 4px 6px -2px rgba(0, 0, 0, 0.267);
      font-size: 0.875rem;
      z-index: 9999;
      min-width: 10rem;
      padding: 0.5rem 0;
      list-style: none;
      background-color: #f8f3e3;
      border-radius: 0.5rem;
      display: none;
    }
    .dropdown.dropdown-submenu {
      position: absolute;
      left: 80%;
      border-radius: 0.5rem;
      /* padding: 0rem; */
      text-align: center;
      

      
    }
  }
  