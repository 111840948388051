@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
.preloader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #F4EFEC;
    opacity: 1;
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    z-index: 9999; /* Ensure the preloader is above other content */
  }
  
  .preloader-container.hidden {
    opacity: 0;
    transform: translateY(-100vh);
  }
  
  .logo-container {
    text-align: center;
  }
  
  .logo-preloader {
    /* margin-top: 5vh; */
    width: 10rem;
    position: relative;
    /* border-radius: 25%; */
  }
  
  .progress-bar {
    width: 80%;
    height: 4px;
    border-radius: 80%;
    margin-top: 20rem;
    background-color: #F4EFEC;
    
  }
  
  .progress {
    height: 100%;
    border-radius: 5%;
    background-color: #82480C;
    transition: width 0.5s ease-in-out;
  }
  
  .logo.animate__animated.animate__fadeIn {
    --animate-duration: 6s;
  }

  .logo-subtitle{
    font-family: "Lato", sans-serif;
    color: #82480C;
    /* margin-top: 5vh; */
    --animate-duration: 7s;
    --animate-delay: 3s;
  }