@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
.section21{
    padding: 20px;
    background-color: #F4EFEC;
    overflow: hidden;
    margin-top: 5%;
  }
  .logo-footer{
    margin-left: 2rem;
  }
  /* Beige - F4EFEC 
Brown - 82480C */
  /* .banner-content {
    width: 100%;
    max-width: 50%;
    padding: 10px;
    /* align-items: center; */
  /* } */
  
  /* .banner-heading {
    font-size: 48px;
    color: #82480C;
    letter-spacing: -0.5px; */
    /* text-align: center; */
  /* } */
  
  /* .banner-action {
    display: flex;
    flex-wrap: wrap;
  } 
  
  .cta-button {
    margin-top: 16px;
  } */
  
  /* .flex{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  
  }
   */
  /* .cta-link {
    margin-left: 250px;
    display: inline-block;
    padding: 16px 20px;
    color: rgb(181,181,181);
    font-weight: bold;
    background-color: rgb(255,247,236);
    border-radius: 8px;
    transition: background-color 0.2s ease;
    transition: 0.5s;
    border: 2px #82480C solid;
  }
  
  .cta-link:hover {
    background-color: #82480C;
    /* background-color: rgb(71, 58, 58); */
    /* border: 2px #000000 solid;
    transition: 0.5s;
    color:#000000;
  }
   */ 
  .footer-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-top: 3px #82480C dashed;
  }
  
  .footer-column {
    width: auto;
    padding: 21px;
  }
  
  .footer-heading {
    margin-bottom: 20px;
    /* color: rgb(181,181,181); */
    color: #82480C;
    font-weight: bold;
    font-family: 'playfair display', sans-serif;
    font-size: 1rem;
  }
  
  .footer-column ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .footer-column li {
    margin-bottom: 16px;
  }
  
  .footer-column a {
    font-size: 0.9rem;
    color: #82480C;
    transition: 0.5s;
    text-decoration: none;
    /* font-family: 'Raleway'; */
    font-family: "Lato", sans-serif;
  }
  
  .footer-column a:hover {
    color: #82480C;
    transition: 0.5s;
    border-bottom: 1px #82480C dashed;

}


.footer-column2 li {
    margin-bottom: 16px;
    margin-right: 10px; 
}

.footer-column2 a {
    color: #82480C;
    text-decoration: none;
}

.footer-column a:hover {
    color: #82480C;

}
.footer-column ul.footer-social-media li a.fb:hover {
    color: #3b5998;
    transition: 0.5s;
}
  .footer-column ul.footer-social-media li a.insta:hover {
    color: #e1306c;
    transition: 0.5s;
  }
  .footer-column ul.footer-social-media li a.twitter:hover {
    color: #00acee;
    transition: 0.5s;
  }
  .footer-column ul.footer-social-media li a.youtube:hover {
    color: #c4302b;
    transition: 0.5s;
  }
  .footer-column ul.footer-social-media li a.whatsapp:hover {
    color: #075e54;
    transition: 0.5s;
  }
  .footer-column ul.footer-social-media li a.telegram:hover {
    color: #0088cc;
    transition: 0.5s;
  }
  .footer-column .footer-item-wrap .content {
    color: white;
    transition: 0.5s;
  }
  .footer-column ul.footer-contactus li i.phone{
    color: #82480C;
  }
  .footer-column ul.footer-contactus li i.envelope{
    color: #82480C;
  }
  .footer-column ul.footer-contactus li i.clock{
    color: #82480C;
  }
  .footer-social-media {
    display: flex;
    list-style: none;
    padding: 0;
  }
  .phone:hover{
    cursor: pointer;
  }
  .span-phone-footer:hover{
    border-bottom: 1px dashed #82480C;
  }
  .footer-social-media li {
    margin-right: 20px;
    display: flex;
    align-items: center;
  }
  .phone,
  .envelope,
  .clock {
    margin-right: 6px;
  }
  .footer-contactus li{
  /* color:  rgb(114, 114, 114); */
  color:  #82480C;
  font-family: "Lato", sans-serif;
  font-size: 0.9rem;
  }
  .header p{
    color: #82480C;
    font-family: "Lato", sans-serif;
    font-size: 1rem;
    text-align: center;

  }

  .logo-btm1{
    margin-top: 1rem;
  }
  .logo-btm2{
    margin-left: 1rem;
  }
  .header h1{
    max-height: 75px;
    font-family: 'Vivaldi';
    /* src: url('https://example.com/path/to/vivaldi.woff2') format('woff2'),
        url('https://example.com/path/to/vivaldi.woff') format('woff'),
        url('https://example.com/path/to/vivaldi.ttf') format('truetype'); */
    font-weight: normal;
    font-style: normal;
    font-size: 50px;
    transition: 0.5s;
    margin-right: 1rem;
    text-align: center;
  }
.header h1:hover{
  color: #82480C;
  font-size: 55px;
  border-bottom:0.5px #82480C dashed;
  text-shadow: 2px 2px 4px rgba(59, 58, 58, 0.5);
  
}
.header h4{
  font-family: "Lato", sans-serif;
  text-align: center;
  color:#82480C;
  margin-right: 1rem;
  font-size : 1rem;
}

.copyright{
  display: flex;
  justify-content: center;
  align-items: center;
}
.copyright p{
  color: #82480C;
  font-family: "Lato", sans-serif;
}

@media only screen and (max-width:812px){
.header{
  padding-left: 9rem;
}
.footer-column{
  padding: 32px;
}
}
@media only screen and (max-width:700px){
  .header{
    padding-left: 5rem;
  }
}
@media only screen and (max-width:550px){
  .logo-footer{
    width: 80%;
  }
  .header{
    padding-left: 0rem;
  }
  .footer-column{
    padding: 21px;
  }
.copyright p{
  font-size: 12px;
}
.logo-btm2{
  margin-left: 0rem;
}
}
@media only screen and (max-width:365px) {
  .section21{
    padding: 10px;
  }
  .header h1{
    font-size: 2.5rem;
  }
  .header p{
    font-size: 0.7rem;
    margin-left: 0.5rem;
  }

  .footer-column a{
    font-size: 0.7rem;
  }
  .footer-column2 a{
    font-size: 0.7rem;
  }
  .footer-heading{
    font-size: 0.9rem;
  }
  .footer-contactus li{
    font-size: 0.8rem;
  }
  .footer-column{
    padding: 10px;
  }
}