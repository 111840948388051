
  .menu-items {
    position: relative;
    font-size: 14px;
    font-family: "Playfair display",sans-serif;
    font-weight: 600;
    color: #82480C;
  }
  
  .menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
    color: #82480C;
    margin-top:5px;
  }
  
  .menu-items button {
    font-family: inherit;
    font-weight: 600;
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
    color: #82480C;
    margin-bottom: 6px;
  }
  
  .menu-items a,
  .menu-items button {
    text-align: left;
    padding: 5px 1rem;
    color: #82480C;
    position: relative; 
  }
  
  .menu-items a::after,
  .menu-items button::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    background-color: #82480C;
    transition: width 0.1s ease-in-out; 
  }
  
  .menu-items a:hover::after,
  .menu-items button:hover::after {
    width: 100%; 
  }
  
#arrow-navbar{
  margin-left: 2px;
  font-size: 1.2rem;
}
  @media only screen and (max-width: 900px) {
    .menu-items{
      color: #82480C;
      position: relative;
      
      /* border-bottom: 2px solid #82480C69; */

    }
    .menu-items a{
      color: #82480C;
      text-align: center;
      margin-top: 0rem;
      margin-bottom: 3px;
    }
    .menu-items button{
      color: #82480C;
      text-align: center;
    }
    
  }
  /* .dropdown {
    position: absolute;
    right: 0;
    left: auto;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
                0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 10rem;
    padding: 0.5rem 0;
    list-style: none;
    background-color: #fff;
    border-radius: 0.5rem;
    display: none;
  }
  
  .dropdown.show {
    display: block;
  }
  
  .dropdown.dropdown-submenu {
    position: absolute;
    left: 100%;
    top: -7px;
  }
   */