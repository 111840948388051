@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
.co-ords{
    margin-top: 2rem;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.carousel{
    margin-top: 0rem;
}
.home-main{
    overflow-x: hidden;
}
.home-productNav-main{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    flex-direction: column;
    row-gap: 1rem;
}
.home-productNav-main-header{
    font-size: 1rem;
    font-family: "Lato", sans-serif;
    color:#82480C;


}
.home-productNav{
    width: 100%;
    height: auto;
    display: flex;
    justify-content:center ;
    align-items: center;
    column-gap: 4rem;
    overflow-x: scroll;
    
}
.home-productNav::-webkit-scrollbar {
    display: none;
}
.home-productNav-child{
    position: relative;
    display: flex;
    height: auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 0.6rem;
}
.home-productNav-child:hover .home-productNav-childImg {
    transform: translateY(-6px); 
}
.home-productNav-child:hover .home-productNav-childImgbg {
    transform: translateY(-6px);
}
.home-productNav-child:hover .home-productNav-childImg-logo {
    transform: translateY(-6px);
}

.home-productNav-childImg{
    transition: all 0.2s ease-in;
    width: 5rem;
    color: #82480C;
}
.home-productNav-childImg-logo{
    transition: all 0.2s ease-in;
    width: 4rem;
    margin-top: 0.8rem;
    color: #82480C;
}
.home-productNav-childImgbg{
    transition: all 0.2s ease-in;
    position: absolute;

    width: 4.8rem;
    opacity: 30% ;
    z-index: 10;
    top: 0;
}
.home-productNav-childSubheader{
    font-size: 0.8rem;
    font-family: "Lato", sans-serif;
    color:#82480C;
    text-decoration: none;

}
.whatsbutton-home{
position: fixed;
bottom: 20px;
right: 20px;
background-color: #82490c00;
display: flex;

align-items: flex-end;
justify-content: flex-end;
flex-direction: column;
width:3rem;
transition: all 0.3s ease;
z-index: 9999; 
}
.whatsbutton-home:hover{
    transform: scale(1.2);
}
@media only screen and (max-width: 900px) {
    .home-productNav{
        width: 100vw;
        justify-content: start;
        column-gap: 3rem;
        padding-right: 1.5rem;
    }
}
@media only screen and (max-width: 450px) {
    .home-productNav{
        column-gap: 2rem;
        padding-right: 2rem;
    }
    .home-productNav-child{
        padding-left: 0.5rem;
    }
}
@media only screen and (max-width:398px) {
   .whatsbutton-home{
    width: 3rem;
    bottom: 20px;
    right: 10px;
   } 
}