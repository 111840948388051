/* @import url('https://fonts.googleapis.com/css2?family=Vivaldi&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Kreon&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
.child-wrapper h4 {
  position: relative;
  cursor: pointer;
  color: #82480C;
  font-family: "Lato", sans-serif;
  font-size: 2rem;
  font-weight: 400;
}

.child-wrapper h4::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #82480C;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.child-wrapper h4:hover::before,
.child-wrapper h4.active2::before {
  transform: scaleX(1);
}

.active2 {
  border-bottom: 3px solid #82480C;
}

.now-featuring h1 {
  margin-top: 2vh;
  display: flex;
  justify-content: center;
}

.scroll-container {
  overflow-x: scroll;
  /* overflow-y: hidden; */
  /* white-space: nowrap; */
  z-index: 999;
  margin-bottom: 20px;
  scrollbar-width: thin; 
  width: 100%;
}

/* Webkit-based browsers (Chrome, Safari, Edge) */
.scroll-container::-webkit-scrollbar {
  width: 1px;
  
}

.scroll-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;

}

.scroll-container::-webkit-scrollbar-thumb {
  background: #82480C;
  border-radius: 10px;
  cursor: pointer;
}


.parent-container-featuring {
  display: flex;
  flex-direction: column;
  transition: all ease-in-out 0.3s;
  background-color: #F4EFEC;
}

.parent-container-featuring:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.parent-container-featuring h1 {
  letter-spacing: 3px;
  font-family: 'Playfair Display', serif;
  font-size: 3rem;
  border-top: 1px solid #82480C;
  border-bottom: 1px solid #82480C;
  padding: 2% 2%;
  color: #82480C;
}

.child-wrapper {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 2rem;
  margin-top: 1rem;
}
.image-link{
  display: inline-block;
  text-decoration: none; 
}
.child-container {
  
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  gap: 1rem;
  padding: 10px;
  transition: box-shadow 0.3s ease-in-out;
  flex-wrap: nowrap;
}

.child-container:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.child-container::-webkit-scrollbar {
  display: none;
}

.child-container-img {
  width: 20rem;
  height: auto;
  object-fit: cover;
  /* overflow-x: scroll; */
  transition: transform 0.3s ease-in-out;
  border-radius: 1rem;
}

.child-container-img:hover {
  transform: scale(0.9);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

/* @media only screen and (max-width: 1024px) {
  .child-container-img {
    max-width: 100%;
  }
} */

/* @media only screen and (min-width: 1024px) {
  .child-container-img {
    aspect-ratio: 3/4;
  }
} */

@media only screen and (max-width: 768px) {
  .child-container-img {
    margin-right: 1rem;
    width: 15rem;
    /* aspect-ratio: 3/4; */
  }
  .parent-container-featuring h1 {
    font-size: 2rem;
  }
  .child-wrapper h4 {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 512px) {
  .scroll-container::-webkit-scrollbar {
    width: 1px;
    display: none;
  }
  
  .scroll-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
    display: none;
  }
  
  .scroll-container::-webkit-scrollbar-thumb {
    display: none;
    background: #82480C;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .child-container-img {
    aspect-ratio: 2/4;
    object-fit: cover;
  }
}
