.button-hover-login {
  background-color: #ffcc00;
  padding: 15px 25px;
  transition: background-color 0.3s ease;
}
.login-form-main{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
.form_container-login {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 50px 40px 20px 40px;  
    border: 2px solid #543609;

    box-shadow: 0px 106px 42px rgba(0, 0, 0, 0.01),
      0px 59px 36px rgba(0, 0, 0, 0.05), 0px 26px 26px rgba(0, 0, 0, 0.09),
      0px 7px 15px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 11px;
    font-family: "Inter", sans-serif;
  }
  
  .logo_container-login {
    box-sizing: border-box;
    width: 80px;
    height: 85px;
    background-image: url('../Components/images/DD-LOGO.png');
    background-size: cover;
    margin-bottom: 20px;
    /* background: linear-gradient(180deg, rgba(248, 248, 248, 0) 50%, #F8F8F888 100%); */
    border: 1px solid #F7F7F8;
    filter: drop-shadow(0px 0.5px 0.5px #EFEFEF) drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
    border-radius: 11px;
  }
  .title_container-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .title-login {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 700;
    color: #212121;
  }
  
  .subtitle-login {
    font-size: 0.725rem;
    max-width: 80%;
    text-align: center;
    line-height: 1.1rem;
    color: #8B8E98
  }
  
  .input_container-login {
    width: 100%;
    height: fit-content;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .icon-login {
    width: 20px;
    position: absolute;
    z-index: 99;
    left: 12px;
    bottom: 9px;
  }
  
  .input_label-login {
    font-size: 0.75rem;
    color: #8B8E98;
    font-weight: 600;
  }
  
  .input_field-login {
    width: auto;
    height: 40px;
    padding: 0 0 0 40px;
    border-radius: 7px;
    outline: none;
    border: 1px solid #979797;
    filter: drop-shadow(0px 1px 0px #efefef)
      drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
    transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
  }
  
  .input_field-login:focus {
    border: 1px solid transparent;
    box-shadow: 0px 0px 0px 2px #242424;
    background-color: transparent;
  }
  .sign-up-main{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  } 
  .sign-in_btn-login {
    width: 100%;
    height: 40px;
    border: 0;
    color:#242424;
    background: #f8f3e3;
    border: 2px solid #543609;
    border-radius: 7px;
    outline: none;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
  }
  .sign-in_btn-login:hover {
    background-color: #543609;
    color: beige;
  }

  .sign-in_ggl-login {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: #f8f3e3;
    text-decoration: none;
    border-radius: 7px;
    outline: none;
    color: #242424;
    border: 2px solid #543609;
    filter: drop-shadow(0px 1px 0px #efefef)
      drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
      transition: all ease-in-out 0.3s;
    cursor: pointer;
  }
  #google-login-icon{
    background-color: #f7f7f800;
  }
  
  .sign-in_ggl-login:hover{
    background-color: #543609;
    color:beige;
    border: 1px solid black ;
  }
  
  .separator-login {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    color: #8B8E98;
  }
  
  .separator-login .line-login {
    display: block;
    width: 100%;
    height: 1px;
    border: 0;
    background-color: #e8e8e8;
  }
  
  .note-login {
    font-size: 0.85rem;
    color: #8B8E98;
    text-decoration: underline;
    cursor: pointer;
    
}

.note-login:hover{
  color: #543609;
}