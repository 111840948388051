/* 
@import url('https://fonts.googleapis.com/css2?family=Vivaldi&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
.filter-button{
  display: flex;
  justify-content: start;
  width: fit-content;
  align-items: center;
  margin-left: 5rem;

}

.colorText {
  color: #82480C !important;
  text-transform: uppercase !important; 
}

.filter-dropdown{
  margin-top: 0.25rem;
  padding: 5px 15px;
  /* margin: 0.5rem 0.5rem; */
  border: 0.1rem solid #82480C;
  border-radius: 0.4rem;
  background-color: transparent;
  color: #82480C;
  font-size: 1rem;
  cursor: pointer;  
  font-family: "Lato", sans-serif;
}
.left-pane-visible {
  background-color: rgba(0, 0, 0, 0.5);
}
.left-pane.visible{
  background-color: #F4EFEC;
}
.filter-label{
  margin: 0 0;
  font-size: 1.3rem;
  font-family: 'Playfair Display', serif;
  color: #82480C;
}
.filter-group{
  margin-top: 0rem;
}
.filters{
  overflow-y: scroll;
  display: flex;
  /* justify-content: space-evenly; */
  flex-direction: column;
  height: 90vh;

  /* gap: 1rem; */
  /* margin-left: 10vh; */
  /* visibility: hidden; */
}
  
.main-container {
    background-color: #F4EFEC;

}

.head-para-container-navigation-body a{
  text-decoration: none;
  color: #82480C;

}
.head-para-container-navigation-body {
  color: #82480C;
  margin-top: 3rem;
  margin-bottom: -2rem;
  padding-left: 5rem;
  font-size: 0.9rem;
  font-family: "Lato", sans-serif;

}

.all {
  text-align: justify;

  
  /* margin-left: 1rem; */
  margin-top: 3rem;
  font-family: 'Playfair Display', serif;
  font-weight: 100;
  color: #82480C;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: auto;
}

.para{
  /* font-size: 0.8rem; */
  display: flex;
  justify-content: flex-start;
  margin-left: 4rem;
  max-width: 50%;
  line-height: 25px;
}

.allp-p {
  text-align: justify;
  margin-left: 0.8rem;
  /* margin-left: 10vh; */
  font-family: "Lato", sans-serif;
  color: #82480C;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  justify-items: center;
  /* margin: 3rem 3rem; */
  margin-top: 0.5rem;
  margin-left: 3rem;
  margin-right: 3rem;
  margin-bottom: 3rem;
  background-color: #F4EFEC;
}

@media screen and (min-width: 1300px) {  
  .card-container {
    grid-template-columns: repeat(3, 1fr);
    background-color: #F4EFEC
  }
  .all{
     margin-left: 10vh; 
  }
}

@media screen and (max-width: 1024px) {
  .card-container {
    grid-template-columns: repeat(3, 1fr);
    background-color: #F4EFEC
  }
  /* .all{
    margin-left: 10vh;
  } */
}

@media screen and (max-width: 768px) {
  .card-container {
    grid-template-columns: repeat(2, 1fr);
    background-color: #F4EFEC
  }
  .filter-button{
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin-left: 0rem;
  
  }
  .head-para-container-navigation-body{
    width: 100%;
    text-align: center;
    margin-top: 3rem;
    margin-bottom: -2rem;
    padding-left: 0rem;
  }
}

@media screen and (max-width: 600px) {

  .card-container {
    grid-template-columns: repeat(2, 1fr);
    margin-left: 10px;
    margin-right:10px;
    /* grid-gap: 0.rem; */
  }
  .all {
    /* margin-left: 3vh; */
    text-align: center;
    width: 100%;
  }
  .allp-p{
    /* margin-left: 3vh; */
    text-align: center;
    width: 100%;

  }
  .navigation{
    margin-left: 3vh;
  }
  .product-shortTitle{
    font-size: 0.65rem;
    font-family: "Lato", sans-serif;
  }
  .product-price{
    font-size: 0.8rem;
    font-family: "Lato", sans-serif;
  }
  .all {
    text-align: center;
    /* margin-left: 10vh; */
    margin-top: 3rem;
    font-family: 'Playfair Display', serif;
    font-weight: 100;  
  }
  .para{
    font-size: 0.8rem;
    /* display: flex;
    justify-content: center; */
    max-width: 100%;
    margin-left: 1rem;
    margin-right:1rem;
    /* width: 80%; */
    line-height: 25px;
  }
  .head-para{
    width: 100%;
    
  }
  
}
  
.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px; /* Adjust the height as needed */
}

.preloader-text {
  font-size: 24px;
  color: #333;
  align-self: center;
}

@keyframes skeleton-animation {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}

.skeleton {
  width: 100%;
  height: 100%;
  background-color: #F4EFEC;
  background-image: linear-gradient(90deg, #F4EFEC, #e6e6e6, #F4EFEC);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  animation: skeleton-animation 1s infinite linear;
}

/* Add this CSS to your existing CSS file or add a new CSS file and import it into your React component */

/* Styles for the pagination container */
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem; /* Adjust the margin as needed */
  margin-bottom: 1.5rem;
}

.chkOut-cart-2{
  margin-top: 1rem;
  padding: 8px 1rem;
  width: 50%;
  border-radius: 20px;
  background-color: transparent;
  color: #82480C;
  transition: all 0.3s ease;
  border: 2px solid #82480C;
  cursor: pointer;
}
.chkOut-cart-2:hover{
  color: #F4EFEC;
  background-color: #82480C;
}
/* Styles for the pagination buttons */
.pagination-btn {
  display: inline-block;
  padding: 5px 15px;
  margin: 0 0.5rem;
  border: 0.1rem solid #82480C;
  border-radius: 0.4rem;
  background-color: transparent;
  color: #82480C;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

/* Styles for the active pagination button */
.pagination-btn.active {
  background-color: #82480C;
  color: #fff;
}

/* Styles for the disabled pagination buttons */
.pagination-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Optional hover effect for pagination buttons */
.pagination-btn:hover {
  background-color: #82480C;
  color: #fff;
}

/* Responsive styles */
@media screen and (max-width: 480px) {
  .pagination-container {
    flex-wrap: wrap;
  }
  .pagination-btn {
    margin: 0.5rem;
  }
  /* .filter-dropdown{ */
    /* -webkit-appearance: none; */
    /* background: -webkit-linear-gradient(white, #fff); */
  /* } */
}


.filterToggler{
  padding: 5px 15px;
  margin: 0.5rem 0.5rem 0.5rem 0.5rem;
  border: 0.1rem solid #82480C;
  border-radius: 0.4rem;
  background-color: transparent;
  color: #82480C;
  font-size: 1rem;
  cursor: pointer;  
  font-family: "Lato", sans-serif;
  /* align-self: center; */
  margin-top: 1.6rem;
}



.left-pane {
  background-color: #F4EFEC;
  border: 2px solid #ccc;
  z-index: 999;
  margin-top: 1.2rem;
  position: fixed;
  left: -300rem;
  top: 0;
  width: 21rem;
  height: auto;
  padding: 20px 10px;  
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.2);
  transition: left 0.3s ease-in-out;
  flex-wrap: wrap;
}

.left-pane.visible {
  left: 0;
  /* height: 100vh; */
  border-radius: 1rem;
}

.left-pane h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.left-pane p {
  font-size: 1.6rem;
  margin-bottom: 2rem;
}

/* .close-button {
  display: block;
  margin-top: 1rem;
  padding: 1rem 2rem;
  font-size: 1.6rem;
  background-color: #82480C;
  color: #fff;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
} */
/* Style for the close button */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .left-pane {
    max-width: 70%;
    left: -300rem;
    position: fixed;
    margin-top: 2rem;
    /* width: 21rem; */
  }
  .close-button {
    margin-top: 2rem;
  }
  .filter-dropdown{
    padding: 1px 3px;
    margin: 0.3rem 0.3rem;
    border: 0.1rem solid #82480C;
  }
}

.left-pane.visible {
  left: 2rem; /* Show the pane when visible */
}

.left-pane h2 {
  margin-top: 0;
}

.left-pane p {
  margin-bottom: 20px;
}

.close-button {
  display: block;
  margin-top: 10px;
  padding: 8px 16px;
  background-color: transparent;
  color: #82480C;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}
.close-button:hover{
  background-color: transparent;
}