/* activities.css */

/* Styling for the tabs */
.react-tabs {
font-family: Arial, sans-serif;
width: 100%;
max-width: 600px;

margin: 0 auto;
}

.react-tabs__tab-list {
display: flex;
justify-content: space-evenly;
align-items: center;
list-style: none;
padding: 0;
margin: 0;
border-bottom: 1px solid #543609;
}
.star-empty{
    display: none;
}
.react-tabs__tab {
cursor: pointer;
padding: 10px 20px;
border: 1px solid #543609;
/* background-color: #f9f9f9; */
}

.react-tabs__tab--selected {
border-bottom-color: #543609;
/* transform:border-bottom-color scaleX(1); */
}
.react-tabs__tab--selected {
    background: #543609;
    border-color: #543609;
    color: #f8f3e3;
    border-radius: 1rem 1rem 0 0;
}

/* Styling for the table */
table {
width: 100%;
border-collapse: collapse;
margin-top: 20px;
}

th,
td {
border: 1px solid #ccc;
padding: 10px;
text-align: center;
}

th {
background-color: #f9f9f9;
font-weight: bold;
}

tbody tr:nth-child(even) {
background-color: #f2f2f2;
}
