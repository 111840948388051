.nav-area2{
background-color: #F4EFEC;
margin-right: 30%;
}
.navbar-admin{
display: flex;
align-items: center;
justify-content: center;
flex-direction: column-reverse;
width: 100%;
background-color: #F4EFEC;
height: 4rem;
border-bottom: #54360937 2px solid;
transition: all 0.3s ease-in-out;
}
