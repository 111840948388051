@font-face {
  font-family: 'oval';
  src: url('../fonts/Oval-Trial-Medium.otf') format('opentype');
  /* Add additional font variations and formats if available */
}

@font-face {
  font-family: 'open sauce semibold';
  src: url('../fonts/OpenSauceOne-SemiBold.ttf') format('TrueType');
  /* Add additional font variations and formats if available */
}

.countdown-container {
  display: flex;
  justify-content: center;
  
}

.flip-container {
  display: flex;
  width: 100%;
  overflow: hidden;
  /* margin-top: 50px; */
  background-color: rgba(255, 255, 255, 0);
  padding-bottom: 20px;
}

.flip-item {
	position: relative;
	width: 120px;
	height: 80px;
	perspective-origin: 50% 50%;
	perspective: 300px;
  color: white;
	border-radius: 3px;
  font-size: 30px;
  font-family: 'open sauce Semibold';
  display: flex; 
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
}

.writtenManner {
  font-family: 'oval';
  
}

.number {
  background-color: #126080;
  min-width: 50px;
  min-height: 40px;
  font-size: 30px;
  padding: 5px;
  border-radius: 10px;
  font-family: 'open sauce semibold';
  text-align: center;
}

@media (max-width: 600px) {
  .number {
  background-color: #126080;
  min-width: 30px;
  min-height: 30px;
  font-size: 25px;
  /* padding: 5px; */
  border-radius: 8px;
  font-family: 'open sauce semibold';
  text-align: center;
  }
  .flip-container{
    width: auto;
    padding-bottom: 20px;
  }
  .flip-item {
    position: relative;
    width: 10vh;
    height: 80px;
    perspective-origin: 50% 50%;
    perspective: 300px;
    color: white;
    border-radius: 3px;
    font-size: 30px;
    font-family: 'open sauce Semibold';
    display: flex; 
    flex-direction: column;
    align-items: center;
    margin: 0 5px;
  }
}