.headerText {
    font-size: 29px;
    text-align: center;
    color:#82480C;
    font-weight: bolder;
    margin:5px;
}

.emailInput {
    background-color: none;
    width: 50%;
    margin-top: 10px;
    height: 30px;
    border: 3px solid #82480C;
    border-radius: 10px;
    padding-left: 10px;
}
.MuiRating-iconFilled {
    color: #82480C;
  }
.outerBox {
    border: 5px solid #82480C;
    width: 50%;
    display: flex;
    flex-direction: column;
    height: fit-content;
    border-radius: 30px;
    margin-top: 20px;
    flex-wrap: wrap;
    padding:10px;
}
.textInput {
    resize: none;
    width: 45%;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
}
.Main {
    display: flex;
    justify-content: center;
}

.label{ 
    text-align: center;
    margin-top: 10px;
    font-size: larger;
    color: #82480C;
    font-weight: 500;
}

.chkOut{
    background-color: #82480C;
    color: #F4EFEC;
    width: 200px;
    height: 40px;
    margin-top: 15px;
    border-radius: 30px;
    border: 0px;
    align-self: center;
    font-size: large;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
  }
  .chkOut:hover{
    background-color: #82490c9c;
    color: #F4EFEC;
    border-radius: 1rem;
  }

  @media (max-width: 600px) {
    .outerBox {
        width: 80%;
        padding: 20px;
    }
    .headerText{
        font-size: 21px;
        font-weight: bolder;
    }
    .label{ 
        text-align: center;
        margin-top: 10px;
        color: #82480C;
        font-weight: 500;
    }
    .textInput {
        resize: none;
        width: 85%;
        margin-top: 10px;
    }
    .emailInput {
        width: 85%;
    }
  }