@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
.Main-FC {
display: flex;
justify-content: center;
flex-direction: column;
align-items: flex-start;
width: auto;
padding: 3rem 5rem;
text-align: left;
color: #82480C;
}

.Header-FC {
font-family: 'Playfair Display', sans-serif;
font-size: 1.5rem;
margin-bottom: 3rem;
/* border-bottom: 2px solid #82480C; */
word-spacing: 0.3rem;
}
.SubHeader-FC{
    font-family: 'Playfair Display', sans-serif;
    font-size: 1.2rem;
    margin-bottom: 3rem;
    border-bottom: 2px solid #82480C;
}
.Content-FC {
    font-family: "Lato", sans-serif;
    font-size: 1rem;
    margin-bottom: 3rem;
    text-align: justify;
    line-height: 1.7;
}
.Bullet-FC{
    color: #82480C;
    margin-right: 1rem;
    font-size: 1rem;
}


.Highlight-FC {
color: #82480C;
text-decoration: none;
position: relative;
transition: all ease-in-out 0.3s;
}

.Highlight-FC::after {
content: '';
position: absolute;
left: 0;
bottom: -2px;
width: 0;
height: 2px;
background-color: #82480C;
transition: width 0.3s;
}

.Highlight-FC:hover::after {
width: 100%;
}
.footer-navigation a{
    text-decoration: none;
    color: #82480C;
  
  }
.footer-navigation {
    color: #82480C;
    margin-top: 3rem;
    margin-bottom: -2rem;
    padding-left: 5rem;
    font-size: 0.9rem;
    font-family: "Lato", sans-serif;
  
  }
.Last-FC {
font-family: "Lato", sans-serif;
font-size: 1rem;
}
@media only screen and (max-width:512px){
    .Main-FC{
    padding: 3rem 2rem;
    width: auto;
    }
    .Header-FC{
        font-size: 1.5rem;
    }
    .Content-FC{
        font-size: 0.8rem;
    }
    .Last-FC{
        font-size: 0.8rem;
    }
}