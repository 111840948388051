/* Popup.css */

.popup-container {
font-family: 'Playfair Display',sans-serif;
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
background-color: rgba(0, 0, 0, 0.5);
z-index: 999;
opacity: 0;
}

.popup-container.show {
    opacity: 1;
}
.popup-content2 {
display: flex;
/* border: 3px solid #82480C; */
flex-direction: row;
justify-content: center;
align-items: center;
width: fit-content;
background-color:beige;

width: fit-content;
height: fit-content;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.popup-img{
    height: 24rem;
}
.popup-img img{
    /* border-radius: 5px; */
width: 16rem;

}

.input-container {
display: flex;
flex-direction: column;
align-items: flex-end;
justify-content: flex-end;
width: fit-content;
}
.nav-area-Popup img{
    width: 5rem;
    padding-right: 9.5rem;
}
.form{
/* width: 90%; */
display: flex;
align-items: center;
justify-content: center;
gap: 5px;
}
.logo-popup{
    width: 2rem;
}
.p{
    font-size: 1.5rem;
    margin-bottom: 2rem;
    /* border-bottom: 1px solid #82480C; */
    text-align: center;
    padding: 5px;
    margin-right: 4rem;
    /* width: 87%; */
    color: #82480C;
    font-family: 'Playfair Display',sans-serif;
}
.p2 {
    /* margin-top: 1rem; */
    width: fit-content;
    font-size: 1.2rem;
    margin-bottom: 2.4rem;
    text-align: center;
    margin-right: 5rem;
    color: #82480C;
    border-bottom: 2px solid #82480C;
}

.user-source {
width: 60%;
padding: 10px;
background-color: #F4EFEC;
border: 1px solid #82480C;
border-radius: 4px;
margin-bottom: 10px;

}
.form option{
    font-family: "IBM Plex mono", sans-serif;
}
.form option:hover{
    background-color: #82480C;
    color: #F4EFEC;
}
#p222{
display: none;   
}
#p223{
display: none;   
}
#p224{
display: none;   
}
.submit21 {
background-color: #82480C;
color: #F4EFEC;
border: none;
padding: 12px 9px;
border-radius: 4px;
cursor: pointer;
margin-bottom: 8px;
transition: all ease-in-out 0.3s;

}

.submit:hover {
background-color: #a16a02;
}

.close-button-popup,
.close-button2 {
background-color: #F4EFEC;
border: none;
padding: 0.2rem;
margin-right: 2px;
border-radius: 0.3rem;
color: #555;
font-size: 14px;
cursor: pointer;
transition: all ease-in-out 0.3s;
}
.close-button-popup2{
    background-color: #F4EFEC;
border: none;
padding: 0.2rem;
margin-right: 2px;
border-radius: 0.3rem;
color: #555;
font-size: 14px;
cursor: pointer;
transition: all ease-in-out 0.3s;
display: none;
}
.close-button-popup:hover,
.close-button2:hover,
.close-button-popup2:hover {
background-color: #82480C;
color: #F4EFEC;
}
.after-sub{
    padding: 4rem;
}
.thank-you{
    font-family: 'Playfair Display',italic;
    font-weight: 500;
    font-size: 3rem;
}

@media only screen and (max-width:583px){
.p{
    margin-right: 2rem;
}
.p2{
    margin-right: 3rem;
}
.nav-area-Popup img{
    padding-right: 8rem;
}
}
@media only screen and (max-width:551px){
.p{
    font-size: 1.2rem;
    margin-right: 1rem;
}
.p2{
    font-size: 1rem;
    margin-right: 1rem;
}
.nav-area-Popup img{
    padding-right: 5rem;
}
}
@media only screen and (max-width:500px){
.p{
    font-size: 1.2rem;
    margin-right: 1rem;
}
.p2{
    font-size: 1rem;
    margin-right: 1rem;
}
.popup-img img{
width: 14rem;
height: 24rem;

}
.nav-area-Popup img{
    padding-right: 5rem;
}

}
@media only screen and (max-width:440px){
    /* .popup-content2{
        flex-direction: column-reverse;
        border-radius: 1rem;
        width: 55%;
        height: fit-content;
        margin-top: 0.5rem;
    } */
    .close-button-popup2{
        display: flex;
        border-bottom: #82480C 1px solid;
    }
    .input-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 24.2rem;
        height: 24rem;
        border-radius: 1rem;
        }
        .animate__fadeIn{
            border-radius: 100%;
        }
    .popup-container.show{
        border-radius: 0rem;
    }
    /* .popup-img img{
        width: 15rem;
        height: 24rem;
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
        }
        .popup-img{
            border-bottom-right-radius: 1rem;
            border-bottom-left-radius: 1rem;
    
        } */
        .popup-img{
            display: none;
        }
        .user-source {
            width: 47%;
            padding: 10px;
            background-color: #F4EFEC;
            border: 1px solid #82480C;
            border-radius: 4px;
            margin-bottom: 10px;
            
            }
            .form option{
                font-family: "IBM Plex mono", sans-serif;
            }
            .form{

            }
            
    .p2{
        margin-right: 0rem;
        font-size: 1.1rem;
        width: fit-content;
        margin-bottom: 3rem;

    }
    .p{
        font-size: 1.5rem;
        display: none;
    }
    /* .nav-area-Popup img{
        padding-right: 0rem;
        background: none;
        border-radius: 1rem;
    } */
    .nav-area-Popup{
        display: flex;
        justify-content: center;
        align-items: center;
        display: none;
    }
    .form {
        width: 60%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        background-color: #82480C00;
        border-radius: 1rem;
    }
    #p221{
        display: none;
    }
    #p222{
        display: flex;
        font-size: 2rem;
        color: #82480C;
        margin-bottom: 2.5rem;
       }
       #p223{
        display: flex;  
        margin-bottom: 2rem;
       }
       #p224{
        display: flex;   
        margin-bottom: 2rem;
        border-bottom: none;
        font-size: 14px;
       }
    .close-button-popup{
        display: none;
    }
    .close-button-popup,
.close-button2 {
    justify-self: flex-end;
    align-self: flex-end;
    margin-right: 1rem;
}
}