@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
.descriptionAndPolicy-product {
  height: 500px;
  /* background-color: #FFF3E2; */
  background-color: #F4EFEC;
  width: 80%;
  justify-content: center;
  height: fit-content;
  border:3px solid #82480C;

}

.contactUs-product {
display: flex;
flex-direction: row;
justify-content: center;
margin: 20px;
flex-wrap: wrap; 
}

.contactUsHeaderTxt-product{
font-size: 20px;
letter-spacing: 1px;
font-family: 'Bacasime Antique', serif;

}


.contactUsContentTxt-product{
font-size: 13px;
letter-spacing: 1px;
color: #82480C;
font-family: 'Bacasime Antique', serif;
}

.contactUs-container-product{
flex-direction: row;
display: flex;
/* margin-left: 100px; */
margin-left: 50px;
margin-right: 50px; 
margin-top: 10px;
margin-bottom: 20px;

}

.main-container-product {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #F4EFEC;
  /* background-color: #F5F5EB; */
}

.navigateBack-product {
  min-height: 75px;
  border:1px solid black
}

.product-product {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px;
}

.needHelp-product{
font-size: 35px;
font-family: 'Bacasime Antique', serif;
font-weight: 300;
text-align: center;
color: #82480C;
margin: revert;

}

.productDetails-product {
  width: 45%;
  /* height: 500px; */
  height: 100vh;
  /* margin-right: 20px; */
  display: flex;
  justify-content: center;
  /* border: 3px solid goldenrod; */
  
}

.productImages-product{
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  margin-left: 40px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  
  /* border:1px solid black; */
}

.enlargedImage-product{
  z-index: 999;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 3%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.childProductImage-product {
  height: 500px;
  margin: 5px;
  /* border-radius: 10px; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
 

.outerDescriptionAndPolicy-product {
  width: 100%;
  display: flex;
  margin-top: 30px;
  justify-content: center;
  align-self: center;
  align-content: center;
  /* flex-direction: column; */
  background-color: #F4EFEC;
}

.mobileSwiper-product{
  max-width: 100%;
  overflow-x: hidden;
}

.outerNeedHelp-product{
  background-color: #F4EFEC;
  margin-top: 30px;
  /* justify-content: center; */
  /* align-content: center; */
  text-align: center;
  width: 80%;
  /* justify-self: center; */
  /* align-items: center; */
  align-self: center;
  margin-bottom: 20px;
  border: 3px solid #82480C;
}

/* .add-cart p {
  font-size: 28px;
  color: #444;
  margin-right: 15px;
} */

/* For description */
.row-container-product {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px;
  flex-wrap: wrap;
}

.column-container-product {
  display: flex;
  flex-direction: column;
}

.box-product {
  /* background-color: #f0f0f0; */
  padding: 20px;
  max-width: 300px;
  height:100%;
  margin: 10px;


}

.descBox-product{
  display: flex; 
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: 20px;

}

.descHeader-product{
  letter-spacing: 2px;
  font-size: 15px;
  /* color:black; */  
  color:#82480C;
  margin: revert;
  font-family: 'Kreon', serif;
}

.descContent-product {
  max-width: 200px;
  color:#82480C;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  font-size: 13px;
}

.info-product{
  color:#82480C;

}

.info-product{
  color:#82480C;
}


/* For returns and exchange */
.content-product {
  display: none;
  transition: all 0.5s ease-in-out;

}

.visible-product {
  display: block;
  animation: slideDown 0.2s ease-in-out;
  margin-left: 30px;
}

.listItem-product{
  font-weight: 400;
  font-size: 13px;
  font-family: 'IBM Plex Mono', monospaces;
  /* margin-left: -30px; */
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.underline-product{
  border-bottom: 1px solid grey;
  height: 2px;
  margin-bottom: 10px;
  margin-left: 20px;
}


.mobileChildProductImage-product {
  visibility: collapse;
  display: none;
  
}

@media (max-width: 600px) {
  .product-product {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    justify-content: center;
    flex-wrap: wrap;
    /* align-items: center; */
  }
  .productImages-product{
    /* width: 50%;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
     */
    display: none;
    /* margin-left: 40px; */
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    
    /* border:1px solid black; */
  }
  .row-container-product{
    justify-content: center;
  }
  .descContent-product{
    text-align: center;
    display: block;
  }
  .box-product{
    text-align: inherit;
  }
.mobileChildProductImage-product{
  height:auto;
  aspect-ratio: 6/9;
  max-width:100%;
  /* display:block; */
  visibility: visible;
  width: 80%;
  margin-left: 2.5rem;
  display: flex;
  justify-content: center;
  border-radius: 1rem;
  /* border-radius:  10px; */
}
.descriptionAndPolicy-product{
  width: 88%;
}
.outerNeedHelp-product{
  width: 88%;

}
  .productDetails-product {
    width: 90%;
    /* height: 500px; */
    height: fit-content;
    margin-top: 20px;
    /* margin-right: 20px; */
    display: flex;
    justify-content: center;
    /* border: 3px solid goldenrod; */
    align-self: center; 
  }
  .contactUsHeaderTxt-product{
    font-size: medium;
    color: #82480C;
  } 
  .contactUs-container-product{
    margin-left:0;
    width: 95%;
    margin-right: 0;
    
  }
  .contactUs-product{
    margin-left: 10;
  }
  .descHeader-product{
    text-align: center;
  }
}