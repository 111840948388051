/* userDeets.css */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap');

.main{
  background-image: url("../Components/images/bg.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}



.user-details-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: transparent;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  

  /* h1 {
    text-align: center;
    margin-bottom: 20px;
  } */
  .user-details{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 1rem;
    /* background-image: url("./bg.jpeg"); */
    background-color: transparent;
    /* text-align: center; */
  }
  
  .user-detail-field {
    margin: 10px 0;
    padding: 5px;
    /* border-bottom: 1px solid #ddd; */
    font-family: 'IBM Plex Mono';
    color: #000;
    font-weight: 600;
    background: transparent;
  }

  .edit-btn{
    padding: 8px;
    align-self: center;
    background:transparent;
    border: 2px solid #82480C;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    margin-bottom: 10px;
    transition: all 0.3s ease;
  }
  .edit-btn:hover{
    background-color: #82480C;
    color: #F4EFEC;
  }
  .btn-div{
    background: transparent;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .container2{
    background-color: transparent;
    /* max-width: 20rem; */
  }
  .container1{
    background-color: transparent;
    margin-left: 10rem;

  }
  .img-name{
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;  
    background-color: transparent;
    max-width: 20rem;
  }

  .user-pic{
    border-radius: 50%;
    max-width: 15rem;
    background-color: transparent;
  }
  .Name{
    margin-top: 0.5rem;
    font-family: 'Playfair Display',sans-serif;
    font-weight: 550;
    font-size: 2rem;
    background-color: transparent;
  }
  .user-detail-field strong {
    font-weight: bolder;
    color: black;
    margin-right: 10px;
    background-color: transparent;
  }
  
@media screen and (max-width:600px){
    .user-pic{
      margin-top: 1rem;
    }
    .user-details{
      flex-direction: column;
      align-items: center;
      margin-left: 1rem;
    }
    .container1{
      align-items: center;
      margin-left: 0px;
    }
    
}