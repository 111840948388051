/* Popup.css */

.popup-container {
    font-family: 'Playfair Display',sans-serif;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    opacity: 0;
    }
    
    .popup-container.show {
        opacity: 1;
    }
    .popup-content2 {
    display: flex;
    /* border: 3px solid #82480C; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color:#F4EFEC;
    width: fit-content;
    height: fit-content;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    .popup-img{
        height: 24rem;
    }
    .popup-img img{
        /* border-radius: 5px; */
    width: 16rem;
    
    }
    
    .input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: fit-content;
    }
    .nav-area-Popup img{
        width: 5rem;
        padding-right: 0rem;
        margin-bottom: 1.6rem;
    }
    .p1{
        font-size: 1.1rem;
        margin-bottom: 1.5rem;
        /* border-bottom: 1px solid #82480C; */
        text-align: center;
        padding: 5px;
        margin-right: 0rem;
        /* width: 87%; */
        color: #82480C;
        font-family: 'lato',sans-serif;
    }
    .p21 {
        /* margin-top: 1rem; */
        width: fit-content;
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
        text-align: center;
        margin-right: 1rem;
        margin-left: 1rem;
        color: #82480C;
        /* border-bottom: 2px solid #82480C; */
    }
    .p2121 {
        /* margin-top: 1rem; */
        width: fit-content;
        font-size: 1rem;
        margin-bottom: 1.5rem;
        text-align: center;
        margin-right: 1rem;
        margin-left: 1rem;
        color: #82480C;
        /* border-bottom: 2px solid #82480C; */
    }
    #p221{
        margin-bottom: 1.5rem;
    }
    .submit21 {
    background-color: #82480C;
    color: #F4EFEC;
    border: none;
    padding: 9px 30px;
    font-size: 1rem;
    border-radius: 8px;
    cursor: pointer;

    margin-bottom: 2rem;
    
    transition: all ease-in-out 0.3s;
    
    }
    
    .submit21:hover {
    background-color: #a16a02;
    }
    
    .close-button-popup{
    background-color: #F4EFEC;
    border: none;
    padding: 0.2rem;
    border-radius: 0.3rem;
    color: #555;
    font-size: 14px;
    margin-left: 85%;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    }

    .close-button-popup:hover{
    background-color: #82480C;
    color: #F4EFEC;
    }
    .MobilePopup{
        display: none;
    }
    @media only screen and (max-width:551px){
    .p1{
        font-size: 1.2rem;
        /* margin-right: 1rem; */
    }
    .p21{
        font-size: 1.2rem;
        /* margin-right: 1rem; */
    }
    }
    @media only screen and (max-width:500px){
    .p1{
        font-size: 1.2rem;
    }
    .p21{
        font-size: 1rem;
    }
    .popup-img img{
    width: 15rem;
    height: 24rem;
    
    }

    }
    @media only screen and (max-width:440px){
        .MobilePopup{
            display: flex;
            height: fit-content;
            position: relative;
            transition: all ease-in-out;
        }
        .MobilePopup2{
            height: 30rem;
        }
        .MobilePopup-image{
            background-color: #ffffff00;
            width: 20rem;
            height: 30rem;
        }
        .close-button-popup2{
            position: absolute;
            right: 0;
            margin: 2px 2px;
            background-color: transparent;
            color: #F4EFEC;
            font-size: 1rem;

        }
        /* .LoginButtonPopUpDiv{

            position: absolute;
            right: 1.5rem;
            top: 66%;
            margin: 2px 2px;
            background-color: #f4efec98;
        } */
        .LoginButtonPopUp{
            position: absolute;
            right: 1.5rem;
            top: 66%;
            margin: 2px 2px;
            color: #F4EFEC;
            font-size: 1rem;
            border-radius: 0.9rem;
            padding: 6px 15px;
            border: 1px solid #F4EFEC;
            background-color: #f4efec00;
            font-family: "IBM PLEX MONO";
            font-weight: 600;
            
        }
        .submit212{
            position: absolute;
            right: 0;
            margin: 2px 2px;
            
        }
        .input-container{
            display: none;
        }

    }
    @media only screen and (max-width:320px){
        .MobilePopup2{
            height: 27rem;
        }
        .MobilePopup-image{
            background-color: #ffffff00;
            width: 18rem;
            height: 27rem;
        }
    }