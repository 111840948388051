/* userForm.css */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap');

/* * {
  box-sizing: border-box;
} */

.address-txt {
  margin-top: 0px;
}

.heading {
  font-family: 'Playfair display', serif;
  margin: 0;
  font-size: 25px;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 600;
  padding: 0;
  color: #82480C;
  /* background-color: #fff; */
}

.country-aptno{
  display: flex;
  width: 100%;
  gap: 2rem;
}
.address-field{
  width: 40rem;
}
.form-container {
  max-width: 45rem;
  margin: 5vh auto;
  padding: 2rem;
  border: 3px solid #82480C;
  border-radius: 1rem;
  background-color: #F4EFEC;
}

.form-container h1 {
  text-align: center;
  font-family: 'Playfair Display', serif;
  font-weight: 600;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.form-inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  background-color: transparent;
}

.form-inputs label {
  flex: 1;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  color: #82480C;
  font-size: 1.2rem;
}

.form-inputs label input {
  padding: 1rem;
  color: #000;
  font-weight: 550;
  border: 1px solid #82480C;
  background-color: #F4EFEC;
  border-radius: 0.4rem;
  font-size: 1rem;
}

.form-inputs h2 {
  width: 100%;
  font-family: "Playfair display", serif;
  /* margin-top: 0.5rem; */
  color:#82480C;
  /* border-bottom: 1px solid #ccc; */
  /* line-height: 0.1em; */
  text-align: center;
}

.form-inputs h2 span {
  background-color: #F4EFEC;
  padding: 0 1rem;
  font-size: 1.2rem;
  color: #333;
}

.btn {
  display: flex;
  justify-content: center;
}
/* 
.outline-button {
    background-color: #82480C;
    color: #F4EFEC;
    width: 200px;
    height: 40px;
    align-self: center;
    font-size: large;
    cursor: pointer;
    border-radius: 20px;
} */
/* 
.outline-button:hover {
  background-color: #82480C93;
  color: #fff;
  border-radius: 20px;
} */

/* Media Query for Mobile Devices */
@media screen and (max-width: 768px) {
  .form-container {
    padding: 1rem;
    margin: 1rem 1rem;
  }

  .address-field{
    flex-wrap: wrap;
    max-width: 90%;
  }

  .country-aptno{
    flex-wrap: wrap;
  }

  .form-inputs {
    flex-direction: column;
    border-color: #F4EFEC;
  }

  .form-inputs label {
    flex: none;
    width: 100%;
  }

  .outline-button {
    width: 100%;
  }

}
