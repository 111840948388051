/* userForm.css */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap');

/* * {
  box-sizing: border-box;
} */

.address-txt {
  margin-top: 0px;
}
.popup_userform {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content_userform {
  background-color: white;
  width: 25%;
  padding: 20px;
  border-radius: 2rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Shadow effect */
  
}

.heading {
  font-family: 'Playfair display', serif;
  margin: 0;
  font-size: 25px;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 600;
  padding: 0;
  color: #543609;
  /* background-color: #fff; */
}

.country-aptno{
  display: flex;
  width: 100%;
  gap: 2rem;
}
.address-field{
  width: 40rem;
}
.form-container {
  max-width: 45rem;
  margin: 5vh auto;
  padding: 2rem;
  border: 3px solid #543609;
  border-radius: 1rem;
  background-color: #f8f3e3;
}

.form-container h1 {
  text-align: center;
  font-family: 'Playfair Display', serif;
  font-weight: 600;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.form-inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  background-color: transparent;
}

.form-inputs label {
  flex: 1;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  color: #543609;
  font-size: 1.2rem;
}

.form-inputs label input {
  padding: 1rem;
  color: #000;
  font-weight: 550;
  border: 1px solid #543609;
  background-color: #F8F3E3;
  border-radius: 0.4rem;
  font-size: 1rem;
}

.form-inputs h2 {
  width: 100%;
  font-family: "Playfair display", serif;
  /* margin-top: 0.5rem; */
  color:#543609;
  /* border-bottom: 1px solid #ccc; */
  /* line-height: 0.1em; */
  text-align: center;
}
.userform_content{
  width: 100%;
  font-family: "Lato", sans-serif;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color:#543609;
  text-align: center;
}

.form-inputs h2 span {
  background-color: #F8F3E3;
  padding: 0 1rem;
  font-size: 1.2rem;
  color: #333;
}

.btn {
  display: flex;
  justify-content: center;
}

.outline-button {
    background-color: #543609;
    color: #f8f3e3;
    width: fit-content;
    height: 40px;
    align-self: center;
    font-size: large;
    
    border-radius: 20px;
} 
.close-button-popup-userform{
  background-color: #F4EFEC;
  border: none;
  padding: 0.2rem;
  border-radius: 0.3rem;
  color: #555;
  font-size: 14px;
  margin-left: 95%;
  right: 0;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  }

  .close-button-popup-userform:hover{
  background-color: #82480C;
  color: #F4EFEC;
  }
.popUp_content{
  font-family: "Lato", sans-serif;
  font-size: 1.2rem;
  padding: 1rem 0rem;
  margin-top: 1.5rem;
}
.outline-button:hover {
  background-color: #54360993;
  color: #fff;
  border-radius: 20px;
}

/* Media Query for Mobile Devices */
@media screen and (max-width: 768px) {
  .form-container {
    padding: 1rem;
    margin: 1rem 1rem;
  }
  .popup-content_userform {
    background-color: white;
    width: 60%;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Shadow effect */
  }
  .address-field{
    flex-wrap: wrap;
    max-width: 90%;
  }

  .country-aptno{
    flex-wrap: wrap;
  }

  .form-inputs {
    flex-direction: column;
    border-color: #F8F3E3;
  }

  .form-inputs label {
    flex: none;
    width: 100%;
  }

  .outline-button {
    width: 100%;
  }

}
