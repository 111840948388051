* {
margin: 0;
padding: 0;
}
body{
  background-color: #F4EFEC;
 }
/* @import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities'; */
/* header {
  height: fit-content;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07),
              0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #212529;
} */

::-webkit-scrollbar {
  width: 5px;
}

  ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #82480C; 
  border-radius: 10px;
}





/* ::-webkit-scrollbar:hover {
  cursor: pointer;
} */

