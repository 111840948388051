        .success-wrapper, .cancel-wrapper{
        background-color: #ffffff00;
        min-height: 60vh;
        top: 0;
        z-index: 2;
        position: relative; /* Keep the wrapper relative for positioning */
        overflow: hidden;

        }
        #confetti-canvas {
            position: fixed; /* Position the canvas fixed to cover the whole viewport */
            top: 0;
            left: 0;
            width: 100%;
            height: 10%;
            pointer-events: none; /* Prevent the canvas from blocking mouse interactions */
            z-index: -1; /* Push the canvas below other elements */
        }
        .success, .cancel{
        width: 1000px;
        margin: auto;
        margin-top: 1rem;
        background-color: #F4EFEC00;
        padding: 50px;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        }

        .success .icon {
        color: #82480C;
        font-size: 40px;
        }
        .success h2{
        text-transform: capitalize;
        margin-top: 15px 0px;
        font-family: 'playfair display',sans-serif;
        font-weight: 900;
        font-size: 40px;
        color:#324d67;
        }
        .success .email-msg{
        font-size: 16px;
        font-family: 'IBM plex mono',sans-serif;
        font-weight: 600;
        text-align: center;
        }
        .cancel p{
        font-size: 20px;
        font-weight: 600;
        font-family: 'IBM plex mono',sans-serif;

        }
        .success .description{
        font-size: 16px;
        font-weight: 600;
        font-family: 'IBM plex mono',sans-serif;

        text-align: center;
        margin: 10px;
        margin-top: 30px;
        }
        .success .description .email{
        margin-left: 5px;
        color: #82480C;
        border-bottom: #82480C 2px solid;
        }
        .product-max-qty{
        margin-top: 10px;
        }
        .success-button {
            font-size: 18px;
            padding: 15px 35px;
            border-radius: 5px;
            font-family: 'IBM plex mono';
            outline: 2px solid #82480C;
            outline-offset: 0px;
            background: #82480C;
            border: 0;
            font-weight: bolder;
            color: white;
            transition: all .1s ease-in-out;
            cursor: pointer;
        }
        
        .success-button:hover {
            outline-offset: 3px;
            outline: 3px solid #82480C;
        }
        .fancy {
            background-color: transparent;
            border: 2px solid #000;
            border-radius: 0;
            box-sizing: border-box;
            color: #fff;
            cursor: pointer;
            display: inline-block;
            float: right;
            font-weight: 700;
            letter-spacing: 0.05em;
            margin: 0;
            outline: none;
            overflow: visible;
            padding: 1.25em 2em;
            position: relative;
            text-align: center;
            text-decoration: none;
            text-transform: none;
            transition: all 0.3s ease-in-out;
            user-select: none;
            font-size: 13px;
        }
        
        .fancy::before {
            content: " ";
            width: 1.5625rem;
            height: 2px;
            background: #82480C;
            top: 50%;
            left: 1.5em;
            position: absolute;
            transform: translateY(-50%);
            transform-origin: center;
            transition: background 0.3s linear, width 0.3s linear;
        }
        
        .fancy .text21 {
            font-size: 1.125em;
            line-height: 1.33333em;
            padding-left: 2em;
            font-family: 'IBM plex mono',sans-serif;
            display: block;
            text-align: left;
            transition: all 0.3s ease-in-out;
            text-transform: uppercase;
            text-decoration: none;
            color: #82480C;
        }
        
        .fancy .top-key {
            height: 2px;
            width: 1.5625rem;
            top: -2px;
            left: 0.625rem;
            position: absolute;
            background: #F4EFEC;
            transition: width 0.5s ease-out, left 0.3s ease-out;
        }
        
        .fancy .bottom-key-1 {
            height: 2px;
            width: 1.5625rem;
            right: 1.875rem;
            bottom: -2px;
            position: absolute;
            background: #F4EFEC;
            transition: width 0.5s ease-out, right 0.3s ease-out;
        }
        
        .fancy .bottom-key-2 {
            height: 2px;
            width: 0.625rem;
            right: 0.625rem;
            bottom: -2px;
            position: absolute;
            background: #F4EFEC;
            transition: width 0.5s ease-out, right 0.3s ease-out;
        }
        
        .fancy:hover {
            color: white;
            background: #82480C;
        }
        
        .fancy:hover::before {
            width: 0.9375rem;
            background: white;
        }
        
        .fancy:hover .text21 {
            color: #F4EFEC;
            background-color: #82480C;
            padding-left: 1.5em;
        }
        
        .fancy:hover .top-key {
            left: -2px;
            width: 0px;
        }
        
        .fancy:hover .bottom-key-1,
            .fancy:hover .bottom-key-2 {
            right: 0;
            width: 0;
        }
        @media screen and (max-width:800px) {
        .success-wrapper, .cancel-wrapper{

        min-height: 69vh;
        }
        .success, .cancel {
            width: 370px;
        margin-top: 100px;
        padding: 20px;
        }
        .success{
        height: 350px;
        }
        .success h2{
        font-size: 17px;
        }
        .btn-container{
        width: 300px;
        margin: auto;
        }

        }