/* UpdateStatusPopup.css */

/* Style for the overlay */
.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Style for the modal content */
  .ReactModal__Content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }
  
  .h2-updatestatus {
    margin-bottom: 10px;
    background-color: antiquewhite;
  }
  
  /* label {
    display: block;
    margin-bottom: 15px;
  } */
  .checkbox-wrapper-43 input[type="checkbox"] {
    display: none;
    visibility: hidden;
  }

  .checkbox-wrapper-43 label {
    display: inline-block;
  }

  .checkbox-wrapper-43 .check {
    cursor: pointer;
    position: relative;
    margin: auto;
    width: 18px;
    height: 18px;
    -webkit-tap-highlight-color: transparent;
    transform: translate3d(0, 0, 0);
  }
  .checkbox-wrapper-43 .check:before {
    content: "";
    position: absolute;
    top: -15px;
    left: -15px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: rgba(34,50,84,0.03);
    opacity: 0;
    transition: opacity 0.2s ease;
  }
  .checkbox-wrapper-43 .check svg {
    position: relative;
    z-index: 1;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #c8ccd4;
    stroke-width: 1.5;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease;
  }
  .checkbox-wrapper-43 .check svg path {
    stroke-dasharray: 60;
    stroke-dashoffset: 0;
  }
  .checkbox-wrapper-43 .check svg polyline {
    stroke-dasharray: 22;
    stroke-dashoffset: 66;
  }
  .checkbox-wrapper-43 .check:hover:before {
    opacity: 1;
  }
  .checkbox-wrapper-43 .check:hover svg {
    stroke: #4285f4;
  }
  .checkbox-wrapper-43 input[type="checkbox"]:checked + .check svg {
    stroke: #4285f4;
  }
  .checkbox-wrapper-43 input[type="checkbox"]:checked + .check svg path {
    stroke-dashoffset: 60;
    transition: all 0.3s linear;
  }
  .checkbox-wrapper-43 input[type="checkbox"]:checked + .check svg polyline {
    stroke-dashoffset: 42;
    transition: all 0.2s linear;
    transition-delay: 0.15s;
  }

  .checkbox-label21{
    background-color: antiquewhite;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 2rem;

  }
 .update_status{
    background-color: antiquewhite;
    /* padding-right: 2rem; */
 } 
  .update-status {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 2rem;
    background-color: #4caf50;
  }
  

  .cancel-status{
    background-color: #f44336;
    padding: 10px 15px;
    border: none;
    margin-left: 1rem;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 2rem;
    color: white;
  }
  
  button:hover {
    opacity: 0.8;
  }
  