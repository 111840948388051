@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
.container21{
display: flex;
flex-direction: column;
text-align: center;
justify-content: center;
align-items: center;
font-family: "Lato", sans-serif;
margin-top: 2rem;

}
.container21 h1{
color: #82480C;
font-family: 'kreon';
font-weight: 300;
font-size: 3rem;
border-bottom: 2px solid #82480C;
margin-bottom: 1.25rem;

}
.container21-navigation{
    width: 100%;
    justify-content: start;
    display: flex;
    align-items: center;
}
.container21-navigation-body a{
    text-decoration: none;
    color: #82480C;

}
.container21-navigation-body {
    color: #82480C;
    padding-left: 3rem;
    font-size: 0.9rem;
    font-family: "Lato", sans-serif;

}
.generation{
display: flex;
flex-direction: column;
align-items: center;
}
.generation p {
color:rgb(19, 18, 29);
font-size: 1.2rem;
width: 70rem;
text-align: justify;
margin-bottom: 3rem;
}
.generation2{
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
gap: 15%;
margin-bottom: 3rem;
}
.content21{
    border: 1px solid #82480C;
    padding: 1rem;
    border-radius: 2rem;
}

.generation2 p {
color:rgb(19, 18, 29);
font-size: 1rem;
width: 35rem;
text-align: justify;

}

.generation2 h2{
margin-bottom: 4rem;
color:#82480C;
font-size: 1.5rem;
margin-bottom: 0.625rem;
width: 100%;
}
.generation h2{
margin-bottom: 4rem;
color:#82480C;
font-size: 1.8rem;
margin-bottom: 0.625rem;
}
.generation h3{
    color:#82480C;
    font-size: 1.2rem;
    width: 90%;
    margin-bottom: 1rem;
}
/* .carousel-container {
width: 50%;

}


.carousel-image {
    width: 100%;
    height: 100%;
} */
.carousel-container2 {
    
    width: 40rem;
}
.carousel-image2 {
    border-radius: 2rem;
width: 40rem;
height: 30rem;
}
#invert-column-carousel{
    display: none;
}
@media only screen and (max-width:1400px) {
        .generation2{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 2%;
        margin-bottom: 3rem;
        }
        .carousel-container2 {
            
            width: 35rem;
        }
        .carousel-image2 {
            border-radius: 2rem;
        width: 35rem;
        height: 30rem;
        }
        

}
@media only screen and (max-width:1180px) {
        
        .generation p {
        color:rgb(19, 18, 29);
        font-size: 1rem;
        width: 90%;
        text-align: justify;
        margin-bottom: 3rem;
        }

        .generation2 p {
        color:rgb(19, 18, 29);
        font-size: 1rem;
        width: 30rem;
        text-align: justify;
        
        }
        

        .carousel-image2 {
            border-radius: 2rem;
        width: 35rem;
        height: 28rem;
        }
        

}
@media only screen and (max-width:1080px) {
        .generation p {
        color:rgb(19, 18, 29);
        font-size: 1rem;
        width: 80%;
        text-align: justify;
        margin-bottom: 3rem;
        }
        .generation2{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 2%;
            margin-bottom: 3rem;
            }
        .generation2 p {
            color:rgb(19, 18, 29);
            font-size: 1rem;
            width: 50rem;
            text-align: justify;
            }
        
        .carousel-container2 {
            margin-top: 3rem;
            width: 45rem;
        }
        .carousel-image2 {
            border-radius: 2rem;
        width: 45rem;
        height: 33rem;
        }
        #column-carousel{
            display: none;
        }
        #invert-column-carousel{
            display: flex;
        }

}
@media only screen and (max-width:840px) {
    .container21 h1{
        color: #82480C;
        font-family: 'kreon';
        font-weight: 300;
        font-size: 3rem;
        border-bottom: 2px solid #82480C;
        margin-bottom: 1.25rem;
        
        }
        .generation{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
        .generation p {
        color:rgb(19, 18, 29);
        font-size: 1rem;
        width: 80%;
        text-align: justify;
        margin-bottom: 3rem;
        }
        .generation2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2%;
        margin-bottom: 3rem;
        }
        .content21{
            border: 1px solid #82480C;
            padding: 1rem;
            border-radius: 2rem;
        }
        
        .generation2 p {
            color:rgb(19, 18, 29);
            font-size: 1rem;
            width: 35rem;
            text-align: justify;
            }
        
        .generation2 h2{
        margin-bottom: 4rem;
        color:#82480C;
        font-size: 1.5rem;
        margin-bottom: 0.625rem;
        width: 100%;
        }
        .generation h2{
        margin-bottom: 4rem;
        color:#82480C;
        font-size: 1.8rem;
        margin-bottom: 0.625rem;
        }
        
        .carousel-container2 {
            margin-top: 3rem;
            width: 35rem;
        }
        .carousel-image2 {
            border-radius: 2rem;
        width: 35rem;
        height: 27rem;
        }
    #sub-content-retail{
        width: 80%;
        margin-bottom: 1rem;
    }
}

@media only screen and (max-width:596px) {
    .container21 h1{
        color: #82480C;
        font-family: 'kreon';
        font-weight: 300;
        font-size: 2.5rem;
        border-bottom: 2px solid #82480C;
        margin-bottom: 1.25rem;
        
        }
        .generation2 p {
            color:rgb(19, 18, 29);
            font-size: 1rem;
            width: 27rem;
            text-align: justify;
            }
        
        .generation2 h2{
        margin-bottom: 4rem;
        color:#82480C;
        font-size: 1.5rem;
        margin-bottom: 0.625rem;
        width: 100%;
        }
        .generation h2{
        margin-bottom: 4rem;
        color:#82480C;
        font-size: 1.8rem;
        margin-bottom: 0.625rem;
        }
        
        .carousel-container2 {
            margin-top: 3rem;
            width: 28rem;
        }
        .carousel-image2 {
            border-radius: 2rem;
        width: 28rem;
        height: 23rem;
        }
    #sub-content-retail{
        width: 80%;
        margin-bottom: 1rem;
    }
}

@media only screen and (max-width:468px) {
    .container21 h1{
        color: #82480C;
        font-family: 'kreon';
        font-weight: 300;
        font-size: 2.1rem;
        border-bottom: 2px solid #82480C;
        margin-bottom: 1.25rem;
        
        }
        .generation{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
        .generation p {
        color:rgb(19, 18, 29);
        font-size: 1rem;
        width: 80%;
        text-align: justify;
        margin-bottom: 3rem;
        }
        .generation h3{
        color:#82480C;
        font-size: 1.1rem;
        width: 80%;
        margin-bottom: 1rem;
        }
        .generation2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2%;
        margin-bottom: 3rem;
        }
        .content21{
            border: 1px solid #82480C;
            padding: 1rem;
            border-radius: 2rem;
        }
        
        .generation2 p {
            color:rgb(19, 18, 29);
            font-size: 1rem;
            width: 21rem;
            text-align: justify;
            }
        
        .generation2 h2{
        margin-bottom: 4rem;
        color:#82480C;
        font-size: 1.5rem;
        margin-bottom: 0.625rem;
        width: 100%;
        }
        .generation h2{
        margin-bottom: 4rem;
        color:#82480C;
        font-size: 1.5rem;
        margin-bottom: 0.625rem;
        }
        
        .carousel-container2 {
            margin-top: 3rem;
            width: 23rem;
        }
        .carousel-image2 {
            border-radius: 2rem;
        width: 23rem;
        height: 21rem;
        }
    #sub-content-retail{
        width: 80%;
        margin-bottom: 1rem;
    }
}

@media only screen and (max-width:380px) {
    .container21 h1{
        color: #82480C;
        font-family: 'kreon';
        font-weight: 300;
        font-size: 1.8rem;
        
        border-bottom: 2px solid #82480C;
        margin-bottom: 1.25rem;
        
        }
        .generation{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
        .generation p {
        color:rgb(19, 18, 29);
        font-size: 0.9rem;
        width: 80%;
        text-align: justify;
        margin-bottom: 3rem;
        }
        .generation h3{
        color:#82480C;
        font-size: 1rem;
        width: 80%;
        margin-bottom: 1rem;
        }
        .generation2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 3rem;
        }
        .content21{
            border: 1px solid #82480C;
            padding: 1rem;
            border-radius: 2rem;
        }
        
        .generation2 p {
            color:rgb(19, 18, 29);
            font-size: 0.9rem;
            width: 16rem;
            text-align: justify;
            }
        
        .generation2 h2{
        margin-bottom: 4rem;
        color:#82480C;
        font-size: 1.2rem;
        margin-bottom: 0.625rem;
        width: 100%;
        }
        .generation h2{
        margin-bottom: 4rem;
        color:#82480C;
        font-size: 1.2rem;
        margin-bottom: 0.625rem;
        }
        
        .carousel-container2 {
            margin-top: 3rem;
            width: 18rem;
        }
        .carousel-image2 {
            border-radius: 2rem;
        width: 18rem;
        height: 16rem;
        }
    #sub-content-retail{
        width: 80%;
        margin-bottom: 1rem;
        font-size: 0.9rem;
    }
}
@media only screen and (max-width:300px) {
    .container21 h1{
        color: #82480C;
        font-family: 'kreon';
        font-weight: 300;
        font-size: 1.5rem;
        
        border-bottom: 2px solid #82480C;
        margin-bottom: 1.25rem;
        
        }
        .generation{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
        .generation p {
        color:rgb(19, 18, 29);
        font-size: 0.8rem;
        width: 80%;
        text-align: justify;
        margin-bottom: 3rem;
        }
        .generation h3{
        color:#82480C;
        font-size: 0.9rem;
        width: 80%;
        margin-bottom: 1rem;
        }
        .generation2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 3rem;
        }
        .content21{
            border: 1px solid #82480C;
            padding: 1rem;
            border-radius: 2rem;
        }
        
        .generation2 p {
            color:rgb(19, 18, 29);
            font-size: 0.8rem;
            width: 15rem;
            text-align: justify;
            }
        
        .generation2 h2{
        margin-bottom: 4rem;
        color:#82480C;
        font-size: 1.1rem;
        margin-bottom: 0.625rem;
        width: 100%;
        }
        .generation h2{
        margin-bottom: 4rem;
        color:#82480C;
        font-size: 1.1rem;
        margin-bottom: 0.625rem;
        }
        
        .carousel-container2 {
            margin-top: 3rem;
            width: 16rem;
        }
        .carousel-image2 {
            border-radius: 2rem;
        width: 16rem;
        height: 15rem;
        }
    #sub-content-retail{
        width: 80%;
        margin-bottom: 1rem;
    }
}

