.product-card {
  width: 100%;
  max-width: 50vh;
  margin-top: 5vh;
  background-color: #F8F3E3;
  transition: transform 0.3s ease;
}

.product-image-container {
  overflow: hidden;
}

/* Add media query for responsiveness */
@media screen and (max-width: 768px) {
  .product-card {
    max-width: 90%;
  }

}

.product-image1 {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s ease;
  border-radius: 1rem;
}

.product-image1:hover {
  transform: scale(2);
  transition: 1.5s; /* Adjust the scale value to your desired zoom level */
}

.product-price {
  width: fit-content;
  margin-top: auto;
  /* align-self: center; */
  /* margin: 0 0; */
  /* cursor: pointer; */
}

.price-div{
  display: flex;
  justify-content: center;
}


.product-shortTitle {
  margin: auto;
  width: fit-content;
  font-size: 1rem;
  display: flex;
  justify-content: flex-start;
}

.product-details {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #F4EFEC;
}

.price-title {
  font-family: 'Lust';
  font-weight: 400;
  text-align: center;
  color: #543609 ;
  /* display: block; */
  /* justify-content: center; */
  align-items: center;
}

.shop-now-button {
  padding: 0.5rem 0.5rem;
  font-size: 0.8rem;
  font-weight: bold;
  background-color: #543609 ;
  color: #F8F3E3;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
}

.shop-now-button:hover {
  background-color: #0056b3;
}

@media screen and (max-width: 480px) {
  .shop-now-button {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .skeleton-container{
    width: 90%;
    max-height: 20rem;
  }
  .skeleton-image {
    height: 20rem; /* Adjust the height to match the product card image height */
  }
  .logoo{
    width: 85%;
    height: 0.5rem; 
    background: none;
  }
  .skeleton-image::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 20%;
    animation: skeleton-loader 1.5s linear infinite;
  }
  
  .product-image1 {
    width: 100%;
    height: 20rem;
    object-fit: cover;
    transition: transform 0.3s ease;
    border-radius: 1rem;
  }
  .product-details {
    justify-content: space-around;
  }
}

/* Loading skeleton styles */
.skeleton-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  border-radius: 0.4rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.skeleton-image {
  width: 100%;
  height: 20rem; /* Set the height you want for the skeleton image */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e0e0e0;
  border-radius: 0.4rem 0.4rem 0 0;
  position: relative;
  overflow: hidden;
}

/* .skeleton-image img {
  width: 80%; /* Set the width you want for the skeleton image
  height: auto;
} */
.logoo{
  width: 50%;
  height: 10rem; 
  background: none;
}
.skeleton-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: skeleton-loader 1.5s linear infinite;}

.skeleton-details {
  padding: 1rem;
  flex-grow: 1;
}

.skeleton-details::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(to right, transparent 0%, #fff 50%, transparent 100%);
  animation: skeleton-loader 1.5s linear infinite;
  align-items: center;
}

.skeleton-title {
  width: 80%;
  height: 1rem;
  background-color: #e0e0e0;
  border-radius: 0.4rem;
  margin-bottom: 0.5rem;
}

.skeleton-price {
  width: 60%;
  height: 1rem;
  background-color: #e0e0e0;
  border-radius: 0.4rem;
  margin-bottom: 0.5rem;
}

.skeleton-button {
  align-self: flex-end;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  font-weight: bold;
  background-color: #c54d07;
  color: #fff;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
}

.skeleton-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(to right, transparent 0%, #fff 50%, transparent 100%);
  animation: skeleton-loader 1.5s linear infinite;
}

@keyframes skeleton-loader {
  0% {
    left: -100%;
    background-position:center;
  }
  100% {
    left: 100%;
    background-position:center;
  }
}
