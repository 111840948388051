.nav-area {
background-color: #F4EFEC;
margin-right: 18%;
}
.navbar{
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
height: 4rem;
border-bottom: #82480C37 2px solid;
transition: all 0.3s ease-in-out;
}
.Login-user{
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 1rem;
    margin-right: 1rem;
}

.Cart-user{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position:relative;
    cursor: pointer;
}
.no-of-products{
    cursor: default;
    position: absolute;
    top:0;
    right: 0;
    padding: 1.5px 2px;
    border-radius: 1rem;
    background-color: #82480C;
    color: #F4EFEC;
    font-family: "Lato", sans-serif;

}
.cart-navbar{
    width: 2rem;
}
.link-login{
    text-decoration: none;
    color: #82480C;
}
.profile-icon{
    position: relative;
    display: inline-block;
    padding-right: 0.6rem;
}
.default-user{
    /* border: #82480C 1px solid; */
    /* border-radius: 50%; */
    padding: 3px;
    margin-right: 10px;
    width: 1.5rem;
    cursor: pointer;
    margin-bottom: 3px;
    transition: all 0.3s ease;
}
.default-user:hover{
    transform: scale(1.1);
}
.profile-user{
    width: 2.5rem;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid #82480C;
    transition: all 0.3s ease;
}
.profile-user:hover{
    transform: scale(1.1);
}
.profile-user-details{
    position: absolute;
    z-index: 2;
    transition: all ease-in-out 0.3s;
    
}
.after-login-navbar{
    align-items: center;
    background-color: #f4efec;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    position: relative;

    /* margin-right: 1rem; */
    /* border: #82480C 1px solid; */
}
.logout-navbar{
    margin-right: 5px;
    color: #82480C;
    font-family: 'Playfair Display' , sans-serif;
    font-weight: 600;
    background-color: #F4EFEC;
    font-size: 0.9rem;
    margin-left: 5px;
    width: auto;
    /* transition: all ease-in-out 0.3s; */
}
.login-navbar{
    font-size: 1.2rem;
    color: #82480C;
    cursor: pointer;
    width: 1.8rem;
    margin-bottom: 5px;
    padding: 0.3rem 0.3rem;
    position: relative;
    transition: all ease-in-out 0.3s;
}
.login-navbar:hover{
    font-size: 1.2rem;
    color: #82480C;
    cursor: pointer;
    font-weight: 500;
    border-radius: 3rem;
    border: #82480C 1px solid;
    /* transform: scale(1.05); */
    opacity: 0.7;
}
  .login-overlay {
    position: relative;
    display: inline-block;
  }
  .overlay-text {
    position: absolute;
    top: 3.6rem;
    left: 50%;
    cursor: default;
    font-size: 0.8rem;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    color: white;
    padding: 5px 12px;
    border-radius: 4px;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease; /* Smooth transition */
  }
  
  .login-overlay:hover .overlay-text {
    opacity: 1; /* Show overlay text on hover */
  }
.ExitSignout_Main{
    display: flex;
    position: absolute;
    flex-direction: row;
    top: 3.6rem;
    right: 0rem;
    padding: 0.2rem 0.3rem;
    justify-content: center;
    align-items: center;
    /* width: 10rem; */
    border: #82480C dashed 2px;
    margin-bottom: -3rem;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
}
.ExitSignout_Main:hover{
    /* scale: (1.05) !important; */
    transform: scale(1.1);
}
.ExitSignout{
    width: 2rem;
}
.navbar:hover {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
.logo {
text-decoration: none;
color: #82480C;
background-color: transparent;
width: 3.3rem;
transition: 0.5s;
background-color: transparent;

transition: all ease-in-out 0.3s;
}
/* .logo p{
    z-index: 0;
    height: 3rem;
    position: relative;
    font-family: 'Vivaldi';
    font-weight: normal;
    font-style: normal;
    font-size: 3.5rem;
    
} */
.navbr-header{
font-family: 'IBM Plex Mono',sans-serif;
background-color: #82480C;
color: #F4EFEC;
font-size: 0.8rem;
display: flex;
width: 100%;
justify-content: center;
align-items: center;
}
.logo:hover{
    transform: scale(1.2);
    filter: drop-shadow(2px 3px 4px rgba(0, 0, 0, 0.4));
}
/* .logo:hover{
text-shadow: 4px 3px 7px rgba(0,0,0,0.55);} */
.logo2{
display: none;
}
.moving-text-container {
background-color: #82480C;
overflow: hidden;
z-index: 9999;
width: 50%;
}

.moving-text {
background-color: #82480C;
text-align: center;
animation: moveLeftToRight 25s linear infinite ;
animation-delay: 2.1s;
animation-play-state: running;
}
.moving-text:hover{
    animation-play-state: paused;
}

@keyframes moveLeftToRight{
0%{
    transform: translateX(100%);
}
100%{
    transform: translateX(-100%);
}
}

@media only screen and (max-width: 900px) {
.navbar{
    height: 63px;   
    justify-content: space-between;
}
.nav-area {
display: flex;
align-items: center;
margin-left: 30%;
}
/* .Cart-user{
    display: none;
} */
/* .link-login{
display: none;
} */
.moving-text-container{
width: 100%;
}
.moving-text{
animation: moveLeftToRight 20s linear infinite ;

}
.navbr-header{
padding-bottom: 1px;
}
.navbr-header p{
font-size: 0.75rem;
}



}

@media only screen and (max-width: 600px) {
    .nav-area {
        display: flex;
        align-items: center;
        margin-left: 42%;
        }
    }
    
    @media only screen and (max-width: 450px) {
    .profile-user{
        width: 2rem;
    }
    
}
@media only screen and (max-width: 412px) {
.navbar{
    justify-content: space-between;
}
.admin-text{
    text-align: center;
    left: 3.5rem;
    position:relative;
}
.Login-user{
    margin-right: 1rem;
    gap: 0.2rem;
}
.nav-area{
    margin-right: 18%;
}
.profile-user{
    width: 2rem;
}
.default-user{
    width: 1.5rem;
    color: #82480C;
    /* margin-bottom: 0; */
}
.nav-area {
display: flex;
align-items: center;

}
.moving-text-container{
width: 100%;
}
.moving-text{

animation: moveLeftToRight 20s linear infinite ;

}
.navbr-header{
padding-bottom: 1px;
}
.navbr-header p{
font-size: 0.7rem;
}


}

@media only screen and (max-width:345px){
    .navbr-header p{
    font-size: 0.6rem;
    }
    .navbr-header p{
font-size: 0.6rem;
}
.navbar{
    width: 80%;
    gap: 0%;
}
}