.admin-panel {
max-width: 500px;
margin: 0 auto;
padding: 20px;
background-color: #f7f7f7;
border-radius: 5px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-panel h2 {
color: #543609;
font-size: 24px;
margin-bottom: 20px;
text-align: center;
}
.admin-panel h1{
    color: #543609;
    font-size: 40px;
    margin-bottom: 20px;
    text-align: center;
    }

.updateStatus{
    padding: 0.8rem;
    border-radius: 3rem;
    background-color: #543609;
    color: #f7f7f7;
}
.updateStatus:hover{
    cursor: pointer;
}

@media screen and (max-width: 600px) {
.admin-panel {
    max-width: 300px;
}
}
