.moving-BC{
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 3rem;
    background-color: #F4EFEC;
}
.Title-MB h1{
    letter-spacing: 3px;
    font-family: 'Playfair Display', serif;
    font-size: 3rem;
    border-top: 1px solid #82480C;
    border-bottom: 1px solid #82480C;
    padding: 2% 2%;
    margin-bottom: 2rem;
    text-align: center;
    color: #82480C;
}
.moving-imgs {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    flex-direction: row;
    gap: 1rem;
    animation: moveLeftToRight2 18s linear infinite;
    animation-delay: 1s;
    animation-play-state: running;
}
.moving-imgs:hover{
    animation-play-state: paused;   
}
.image-link21{
        display: inline-block;
        text-decoration: none;
}
.img-moving{
    width: 20rem;
    height: auto;
    border-radius: 1rem ;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.img-moving:hover{
    transform: scale(0.9);
}
.main-moving-img-container{

    overflow-x: scroll;
}
@keyframes moveLeftToRight2{
    0%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(-100%);
    }
}
@media only screen and (max-width:512px){
    .Title-MB h1{
        font-size: 2rem;
    }
    .img-moving{
        width: 1rem;
    }
    .main-moving-img-container{
        overflow-x: scroll;
    }
    .moving-imgs{
        animation: moveLeftToRight2 12s linear infinite ;

    }
}
@media only screen and (max-width:768px){
    .img-moving{
        width: 15rem;
    }
}