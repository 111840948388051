@import url('https://fonts.googleapis.com/css2?family=Kreon&display=swap');

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&family=Kreon&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.addToCart-productDetails {
    background-color: #82480C;
    width: 100%;
    color: white;
    padding-top: 12px;
    padding-bottom: 12px;
    cursor: pointer;
    border: none;
    margin-bottom: 1rem;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
}

.addToCart-productDetails:hover {
    background-color: #a77637d5;

}

.benefitsText-productDetails {
    font-size: 12px;
    font-weight: 500;
    color:#82480C;
    margin-left: 0;
    margin: revert;

}
.desc-productDetails {
    color:#82480C;
    max-width: 400px;
    text-align: center;
    font-weight: 500;

}

.multipleSize-productDetails {
    display: flex;
    flex-direction: row;
    max-width: 400px;
    align-items: center;
    justify-content: center;
}

.parentContainer-productDetails {    
    border: 3px solid #82480C;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background-color: #F4EFEC;
    align-self: center;
    text-align: center;
    justify-content: space-evenly;
    align-items: center;
    max-width: 100%;
    min-height: 80%;
}

.price-productDetails {
    font-size: 25px;
    font-weight: 400;
    margin-left: 0;
    margin: revert;
    color: #82480C;

}

.no-of-products-productDetails {
    background: #f57224;
    color: #fff;
    font-size: 10px;
    font-weight: 600;
    padding: 2px;
    width: 14px;
    height: 14px;
    border-radius: 3px;
    position: absolute;
    /* top: -5px; */
    /* left: 60px; */
    top: 21.5%;
    right: 23%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.modal-productDetails{
    flex-direction: column;
    display: flex;

}

.productBenefits-productDetails{
    flex-direction: row;
    display: flex;
    justify-content: space-around;
    margin-top: 45px;
    width: 50vh;
    margin-left: 0;
    margin: revert;

}

.productBigTitle-productDetails {
    font-family: "Lato", sans-serif;
    font-size: 18px;   
    margin-left: 0;
    margin: revert;
    color: #82480C;
}

.productTitle-productDetails {
    font-size: 2rem;
    height: fit-content;
    font-weight: 400;
    text-align: center;
    min-width: 450px;
    font-family: 'Playfair Display', serif;
    color:#82480C;
    margin-left: 0;
    margin: revert;

}

.sizeChart-productDetails {
    color:#82480C;
    font-weight: 500;
    cursor: pointer;
    letter-spacing: 2px;
    margin-left: 0;
    margin: revert;

}

.sizeText-productDetails {
    padding: 10px;
    border: 2px solid grey;
    margin: 5px;
    max-width: 80%;
    min-width: 50px;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;

}

.active-productDetails {
    border: 2px solid saddlebrown;
    color: saddlebrown;
    transition: ease-in;
}

.sizeText-productDetails:hover {
    border: 2px solid saddlebrown;
    color: saddlebrown;
}

.modelDesc-productDetails {
    font-size: small;
    font-style: italic;
    font-weight: 500;
    color:#82480C;
    margin-left: 0;
    margin: revert;


}
.Share-productDetails{
    display:flex ;
    align-items: center;
    flex-direction: column;
    justify-content : space-evenly;
    margin-top: 1rem;
}
.Share-productDetails p{
    /* font-family: 'Playfair Display',sans-serif; */
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 3px;
    color: #82480C;
    /* border-bottom: #82480C 1px solid; */
}
.Share-productDetails-Icons{
    display:flex ;
    align-items: center;
    flex-direction: row;
    justify-content : center;
    gap: 2rem;
    margin-top: 0.6rem;
}

.productDetails-Icon{
    font-size: 1.8rem;
    color: #82480C;
}

.sizeChartImg {
    /* max-height: 100%; */
    width: 100%;
    height: 40rem;
}

.popup-content {
    /* width: 40%; */

    height: auto !important;
}
@media (max-width: 600px) {
    .parentContainer-productDetails {    
        /* max-width: min-content; */
        width: max-content;
        padding: 15px;
    }
    .productBenefits-productDetails {
        width: 100%;
    }
    .productTitle-productDetails {
        width: 100%;
        min-width: 0;
    }
    .multipleSize-productDetails{
        max-width: 100%;
        flex-wrap: wrap;

    }
    .sizeText-productDetails {
        padding: 8px
    }
    .sizeChartImg{
        height: auto;
        width: 100%;
    }
    .popup-content {
        height: fit-content !important;
        width: 80% !important;
    }
}