.header-userDashboard {
    font-family: "Playfair display",sans-serif;
    color: #543609;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2.5rem;

} 

/* custom-tabs.css */

/* Style for the TabList */
.custom-tab-list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    /* border-bottom: 1px solid #ccc; */
    justify-content: space-evenly;
}
  
  /* Style for the individual Tab */
  .custom-tab {
    padding: 10px 20px;
    cursor: pointer;
    width:15rem;
    font-size: 20px;
    text-align: center;
  }
  


  /* Style for the active Tab (with underline) */
  .custom-tab.react-tabs__tab--selected {
    transition: width 0.1s ease-in-out;
    border-bottom: 3px solid #543609; /* Change the color and thickness of the underline as desired */
    background-color: #f8f3e3;
    border-color: none;
    color: #543609;
    font-weight: bolder;
}
  

.custom-tab.react-tabs__tab:focus {
    outline: none;

    /* Add your focused styles here */
    /* background-color: #f0f0f0; */
  }