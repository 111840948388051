.carousel-container {
    width: 100%;
    /* height: auto;
    margin: 0 auto; */
    background-color: #F4EFEC;
  }
.carousel-container-21 {
    width: 100%;
    background-color: #F4EFEC;
    display: none;
  }
  .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: var(--swiper-pagination-bottom,-4px);
    top: var(--swiper-pagination-top,auto);
    left: 0;
    width: 100%;
  }
  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
    background-color: #82480C;
    position: relative;
    top:5%;
    transition: all 0.3s ease-in-out;
  }
  
  @keyframes bulletShiftAnimation {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(100%);
    }
  }
  
  .swiper-pagination-bullet-active {
    padding-left:10px;
    padding-right:10px;
  }
  
  .carousel-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .animate__animated.animate__fadeInBottomLeft {
    --animate-duration: 2s;
  }
  .swiper-pagination{
    position: relative;
  }
  .carousel-image {
    max-width: 100%;
    /* height: 100vh; */
    width: 100%;
    max-height: 100vh;
    
  }
  .swiper-button-prev,
.swiper-button-next {
  background-color: transparent;
  display: none;

}
.overlay-button2{
  bottom: 40px;
    right: 6rem;
    background-color: #82480Cc9;
    
    padding: 10px 2rem;
    border: none;
    border-radius: 0.7rem;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
    position: absolute;
  }
  .overlay-button2:hover{
    animation: fadeIn 1s;
    background-color: #82480C;
  }
  #overlay-p2{
    color: #F4EFEC;
    font-family: 'Playfair Display',sans-serif;
    background-color: #82480C00;
    font-size: 1.5rem;
  }
  #overlay-p2:hover{
    border-bottom: #F4EFEC 2px dashed;
}
.swiper-button-next:after, .swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: 1rem;
  text-align: center;
  background-color: #82480C;
  border-radius: 100%;
  color: #F4EFECcb; 
  text-transform: none!important;
  padding: 8px;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
}
@media only screen and (max-width:768px) {
  .carousel-container {
    width: 100%;
    
  }
  .swiper-button-next:after, .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 1rem;
    text-align: center;
    background: none;
    border-radius: 100%;
    color: #F4EFEC;
    text-transform: none!important;
    padding: 8px;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
}
  .carousel-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .carousel-image {
    max-width: 100%;
    height: 60vh;
    width: 100%;
    
  }
  
}
@media only screen and (max-width:480px) {
  .carousel-image21 {
    max-width: 100%;
    height: auto;
    width: 100%;
    
    
  }
  .overlay-button{
    bottom: 33px;
    right: 5px;
    background-color: #82480Cc9;
    
    padding: 8px 16px;
    border: none;
    border-radius: 0.7rem;
    
    cursor: pointer;
    position: absolute;
  }
  #overlay-p{
    color: #F4EFEC;
    border-bottom: #5f3101 2px dashed;
    font-family: 'Playfair Display',sans-serif;
    background-color: #82480C00;
    font-size: 1rem;
  }
  .carousel-container-21{
    display: flex;
    position: relative;
  }
  .carousel-container{
    display: none;
  }
}
@media only screen and (max-width:400px) {
  /* .carousel-image {
    max-width: 100%;
    height: 70vh;
    width: 100%;
    
  } */
}
@media only screen and (max-width:380px) {
  .carousel-image21 {
    max-width: 100%;
    height: 70vh;
    width: 100%;
    
    
  }
}