@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
.bigTitty-cart {
  font-family: 'Playfair Display', serif;
  font-weight: 520;
  margin-bottom: 1rem;
  color:#82480C;

}



.cartText-cart {
  border-bottom: 5px solid #82480C;
  border-radius: 5px;
  width: fit-content;
  font-weight: 600;
  font-family: 'Playfair Display', serif;
  margin-left: 20px;
  margin-top: 1rem;
  color:#82480C;

}

.chkOut-cart{
  background-color: #82480C;
  color: #F4EFEC;
  width: 200px;
  height: 40px;
  align-self: center;
  font-size: large;
  margin-right: 30px;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}
.chkOut-cart:hover{
  background-color: #82490c9c;
  color: #F4EFEC;
  border-radius: 1rem;
}

.column-container-cart {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  margin-bottom: 20px;
  border-bottom: 1px solid #82480C;
  width: 90%;
  margin-left: 20px;
}

.editProduct-cart {
  width: 50%;
  margin-left: 0;
}

.innerCol-container-cart {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-left: 20px;
}
.innerCol-container-cart h3{
  margin-bottom: 0.6rem;
  color:#82480C;

}
.mainTable-cart{
  margin: auto;
  min-width: 80%;
  border: none;
}

.sideImage{
  max-width: 100%;
  height: fit-content;
  aspect-ratio: 5/8;

}

.parent-container-cart{
    height: fit-content;
    background-color: #F4EFEC;
    margin-bottom: 50px;
    position: relative;
    width: 100%;
}

.productImage-cart{
  height: 350px;
  align-self: center;
  margin-bottom: 20px;
}

.row-container-cart {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; 
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.smallTitty-cart {
  font-family: "Lato", sans-serif;
  margin-bottom: 0.6rem;
  color:#82480C;
}

.sticky-bottom-cart {
  position: fixed;
  display: flex;
  bottom: 0;
  width: 100%;
  border-top:3px solid #82480C;
  background-color: #F4EFEC;
  padding: 10px;
  text-align: center;
  flex-direction: row;
  justify-content: flex-end;
}
.stickyText-cart {
  margin-right: 20px;
  margin-top: 0.5rem;
  font-family: "Lato", sans-serif;
  font-weight: 700;
}

  
  .tableData-cart {
    text-align: center;
    max-width: 200px;
    max-height: fit-content;
    border: 2px solid black;
  }

  .tableText-cart{
    width: 15vh;
  }

  .trashIcon-cart{
    margin-left: 10px;
    transition: all ease 0.3s;
    cursor: pointer;
  }
  
  .trashIcon-cart:hover {
    color:#82480C;
    transform: scale(1.1);
    
  }


  @media screen and (max-width: 600px) {
    .innerCol-container-cart{
      margin-left: 0px;
      align-items: center;
      padding: 10px;
    }
    .sticky-bottom-cart {
      height: 65px;
    }
    .row-container-cart{
      flex-direction: column;
      align-items: center;
      margin-top: 0;
    }
    .trashIcon-cart{
      margin-left: 0;
      margin-top: 10px;
      color:#82480C;
    }
    .bigTitty-cart{
      text-align: center;
    }
    .mainTable-cart{
      padding-bottom:5rem ;
    }
    .parent-container-cart{
      width: 100%;
    }
  }