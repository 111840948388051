.desktopContainer {
    display: none; 
    position: relative; 
    flex-direction: column; 
    gap: 0.75rem; 
    border-color: black;
    padding: 50px;
    border-color: 2px;
}
.desktopClose {
    position: absolute; 
    top: 0; 
    right: 0.25rem; 
    align-self: flex-end; 
    width: 22px;
    margin: 15px;
    height: 22px;    
}
.desktopSizeText {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; 
    font-weight: 600; 
    color: 28px;
    color: black;
}
.desktopSizeSubText {
    width: fit-content; 
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; 
    font-weight: 600; 
    color: 16px;
    color: #a2a2a2;
}
.desktopSubContainer {
    display: flex; 
    flex-direction: row; 
}
.desktopTableRow {
    font-weight: 600; 
    color: 20px;
    color: black;
}
.desktopTableHead {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; 
    border-color: #a2a2a2;
    padding: 15px;
    border-color: 2px;
    
}
.desktopTableData {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; 
    border-color: #a2a2a2;
    padding: 15px;
    border-color: 1px;
}
.desktopTableText {
    text-align: center; 
    color: #a2a2a2;
}
.mobileContainer {
    display: flex; 
    position: relative; 
    flex-direction: column; 
    gap: 0.5rem; 
    padding: 20px;
   
}

.mobileClose {
    position: absolute; 
    top: 0; 
    right: 0.25rem; 
    align-self: flex-end; 
    width: 18px;
    margin: 15px;
    height: 18px;
}
.mobileSizeText {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; 
    font-weight: 600; 
    color: 28px;
    color: black;
    
}
.mobileSubText {
    width: fit-content; 
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; 
    font-weight: 600; 
    color: 16px;
    color: #a2a2a2;
    
}

.mobileSubContainer {
    display: flex; 
    overflow-x: auto; 
    flex-direction: row;     
}
.mobileTable {
    min-width: 100%; 
    table-layout: auto; 
    margin-top: 12px;
}
.mobilethtr {
    font-weight: 600; 
color: 18px;
color: black;
}
.mobileth {
    padding: 5px;
    border-color: #a2a2a2;
    border-color: 2px;    
}
.mobiletrtd {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; 
    border-color: #a2a2a2;
    padding: 5px;
    border-color: 1px;
    
}
@media (min-width: 1024px) { 
    .desktopContainer {   
        display: flex; 
    }
    .mobileContainer {
        display: none; 
    }
}