@font-face {
    font-family: 'boldine';
    src: url('../fonts/Boldline-FreeVersion.otf') format('opentype');
    /* Add additional font variations and formats if available */
  }
  
  @font-face {
    font-family: 'oval';
    src: url('../fonts/Oval-Trial-Medium.otf') format('opentype');
    /* Add additional font variations and formats if available */
  }

  .App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .div-1 {
    flex-direction: column;
    display: flex;
    align-items: center;
  }
  
  .div-2 {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .div-3 {
    flex-direction: column;
    display: flex;
    align-items: center;
  }
  
  .Header {
    color: white;
    font-size: 35px;
    font-weight: bold;
    font-family: 'oval';
    margin-bottom: -20px;
    margin-top: -5px;
  }
  
  .merchTextBox {
    padding: 9px;  
    border-top-left-radius: 8px; 
    border-bottom-left-radius: 8px; 
    width:200px;
    font-size: larger;
    
    border: none;
    border: 2px solid #126080;    
    color:  #126080;
    height: 1rem;
  }

  .merchTextBox::placeholder{
    color: #126080;
    font-family: 'oval';
 
  }


  .subText1 {
    color: white;
    /* font-family: 'Raleway', sans-serif;*/
    font-family: 'oval';
    word-spacing: 5%;
    font-weight: 400;
    letter-spacing: 5px;
    font-size: 35px;
    margin: 0;
    /* margin-bottom: 20px; */
  }
  
  .subText2 {
    color: white;
    font-family: 'oval';
    word-spacing: 5%;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  
  .spanText {
    color: white;
    font-size: 20px;
    font-weight: bolder;
    font-family: 'engravers';
  }
  
  .img {
    width: 110px;
    height: 110px;
    background: none;
  }
  
  /* .golden-border {
    display: flex;
    align-items: start;
    min-height: 100vh;
    min-width: 100vh;
    flex-direction: column;
    background-image: url('../images/DTP_2128.jpg');
    background-position: center;
    background-repeat: no-repeat;
    aspect-ratio: 16/9;
    background-size: cover;
    
  } */
  .golden-border {
    display: flex;
    align-items: flex-start;
    /* justify-content: center; */
    min-height: 100vh;
    background-image: url('../Components/images/DTP_2128.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
  }
  
  /* button:hover {
    background-color: black;
  } */
  
  /* .writtenContent {
    flex-direction: column;
    height: 90vh;
    max-width: auto;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    display: flex;
    margin-left: 100px;
  } */
  .writtenContent {
    height: 90vh;
    max-width: 100%;
    align-items: center;
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    background: none;
    flex-wrap: wrap;
    padding: 0 20px;
  }
  
  .outline-button {
    padding: 8px 16px;
    border: none;

    border-radius: 0px 4px 4px 0px;
    background: transparent;
    color: white;
    background-color: #126080;
    transition: background-color 0.3s;

  }
  
  .outline-button:hover {
    background-color: black;
    color: white;
    border-radius: 0px 4px 4px 0px;
  }
  
  /* toast container */
  .Toastify__toast-container {
    width: 300px;
    background-color: #ffffff00;
  }
  
  /* toast notification */
  .Toastify__toast {
    background-color: #f8f3e3;
    
    font-size: 14px;
    font-family: 'ibm plex mono',sans-serif;
    border-radius: 4px;
    border: #543609 2px solid;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 12px;
  }
  
  /* toast body */
  .Toastify__toast-body {
    margin-left: 8px;
    background-color: #f8f3e3;
    color: #543609;
  }
  
  /* toast progress bar */
  .Toastify__progress-bar {
    background-color: #5c6ac4;
  }
  
  /* toast close button */
  .Toastify__close-button {
    color: #543609;
    font-size: 18px;
    font-weight: bold;
    opacity: 0.7;
  }
  
  .Toastify__close-button:hover {
    opacity: 1;
  }
    
  /* Media Queries */
  /* @media (max-width: 600px) {
    .App-logo {
      height: 20vmin;
    }
    
    .subText1 {
      font-size: 20px;
    }
    
    .subText2 {
      font-size: 16px;
    }
    
    .outline-button {
      padding: 6px 12px;
    }
    
    .Toastify__toast-container {
      width: 250px;
    }
    
    .Toastify__toast {
      font-size: 12px;
      padding: 8px;
    }
    .Toastify__close-button {
      font-size: 16px;
    }
    .golden-border {
      background-image: url('../images/DTP_2128-mob.jpg');
      background-size: contain;
      max-width: max-content;
      justify-content: center;
      align-items: center;

    }
    .writtenContent {
      margin: 0;
    }
      
  }
   */


   @media (max-width: 600px) {
    .App-logo {
      height: 20vmin;
    }
  
    .subText1 {
      font-size: 20px;
    }
  
    .subText2 {
      font-size: 16px;
    }
  
    .outline-button {
      padding: 6px 12px;
    }
  
    .Toastify__toast-container {
      width: 250px;
    }
  
    .Toastify__toast {
      font-size: 12px;
      padding: 8px;
    }
  
    .Toastify__close-button {
      font-size: 16px;
    }
  
    .golden-border {
      background-image: url('../Components/images/DTP_2128-mob.jpg');
      background-size: cover;
      width:100%; 
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
  
    .writtenContent {
      margin: 0;
    }
  }